import React, { useEffect, useMemo, useState } from 'react';
import { CITY_GUIDE_NAMED_CONTENT_TYPE, INFRASTRUCTURE_NAMED_CONTENT_TYPE, TAX_GUIDE_NAMED_CONTENT_TYPE } from 'components/named-content';
import RelatedSearches, { SurroundingCities } from 'components/related-searches';
import Button, { PRIMARY_THEME, TERTIARY_THEME } from 'components/control/button';
import SelectField from 'components/control/select-field';
import { sortOptions } from 'utils/select-options';
import { useIsMobile, useIsSmallMobile } from 'hooks/use-size-class';
import Breadcrumbs from 'components/breadcrumbs';
import { AreaListingsPageFilters, AreaListingsPageFiltersType } from './filters';
import { AreaListingsPageCollapsibleGuide, NamedContent } from './guides';
import ListingsGrid from 'components/listings-grid';
import InternalLinks from 'components/home-page/internal-links';
import useActive from 'hooks/use-active';
import { AVAILABLE_STATUS, Filter, Sort } from 'contexts/preferences/listing-params/types';
import ProviderDisclaimer from './provider-disclaimer';
import { headerIds, searchFilterIds, testIds } from 'constants/test-constants';
import { ProvinceOrStateCode, isUsStateCode } from 'utils/province_or_state';
import prioritizeFeeds from 'utils/prioritize-feeds';
import { capitalizeWords } from '@zoocasa/node-kit/strings/capitalize';
import styles from './style.module.scss';
import FilterIcon from 'components/icon/filter-icon';
import NotificationIcon from 'components/icon/notification-icon';
import { trackEvent } from 'utils/google-tag-manager';
import { GTM_CLICK_AREA_PAGE_SAVE_SEARCH_BUTTON } from 'constants/events';
import LoadWhenVisible from 'components/load-when-visible';
import ConditionalWrapper from 'components/conditional-wrapper';
import { SanitizeName, generateAreaBlurbHeading, getAreaNamedContents, getFooterData } from './get-server-side-props';
import formatNumber from 'utils/format-number';
import { useThemeContext } from 'contexts';
import { ThemeNames } from 'types/themes';
import { buildClassName } from 'utils/build-class-name';
import dynamic from 'next/dynamic';
import ProvinceCitySeoLinks from './province-city-seo-links';
import { CountryCode, CountryCodeList } from 'types/countries';
import { PROVINCE_LOCATION_TYPE } from '../route-matchers';
import MyLinkMyLead from './my-link-my-lead';
import MyLinkMyLeadCreate from 'components/my-link-my-lead-create';
import Cookies from 'js-cookie';
import { MLML_AGENT_COOKIE_NAME, MLML_LEAD_COOKIE_NAME } from 'constants/cookies';

const SearchFilterDeprecationWarning = dynamic(() => import('components/search-filter-deprecation-warning'), { ssr: false });

import type { ListingParams } from 'contexts/preferences/listing-params/types';
import type { Breadcrumb } from 'components/breadcrumbs';
import type SearchPrediction from 'data/search-predictions';
import type { LinkDataType } from 'components/home-page/internal-links';
import type { ListingCardData } from 'components/listing-card';
import type { PartialDeep } from 'type-fest';
import type { AreaListingsRouteMatchObject } from '../route-matchers';
import { InsightsResponse } from '@zoocasa/go-search';

//#region Types
export interface Pagination {
  page: number;
  size: number;
  count: number;
  total: number;
}

export interface AreaListingsPageProps {
  listings: readonly ListingCardData[];
  breadcrumbs: readonly Breadcrumb[];
  footerData: LinkDataType[];
  routeMatchObject: AreaListingsRouteMatchObject;
  pagination: Pagination;
  sort: Sort;
  heading: string;
  areaBlurb: string | null;
  areaBlurbHeading: string | null;
  noListingsInAreaMessage: string;
  hasNoListingsInArea: boolean;
  isCrawler: boolean;
  isCrawlerLighthouse: boolean;
  showGuides: boolean;
  showDisclaimer: boolean;
  genericNamedContent?: NamedContent;
  listingParams: ListingParams;
  showFilterDeprecationWarning?: boolean;
  showNoImageListingsFilterWarning?: boolean;
  relatedSearchesData: {
    areaData: InsightsResponse | null;
    surroundingCities: SurroundingCities[];
  }
  setPageNumber: (pageNumber: number) => void;
  onMobileFilterButtonClick(): void;
  onSortChanged(sort: Sort): void;
  onValueChange: (filters: PartialDeep<Filter>) => void;
  onSaveSearchButtonClick: () => void;
  onLocationSearchClick: (searchPrediction: SearchPrediction) => void;
  onMapIconClick: () => void;
  onMoreButtonClick: () => void;
  onDeprecationWarningButtonClick?: () => void;
  onNoImageListingsFilterWarningClose?: () => void;
}
//#endregion

//#region Constants
const AreaGuideClassName = styles['area-guide'];
const SearchNavClassName = styles['search-nav'];
const TitlePaddingClassName = styles['title-padding'];
const TitleSortRowClassName = styles['title-sort-row'];
const PageTitleClassName = styles['page-title'];
const PageSubtitleClassName = styles['page-subtitle'];
const SubFilterClassName = styles['sub-filter'];
const SortByClassName = styles['sort-by'];
const MobileFilterBtnClassName = styles['mobile-filter-btn'];
const GuideWrapperClassName = styles['guide-wrapper'];
const AreaBlurbClassName = styles['area-blurb'];
const AreaBlurbHeadingClassName = styles['area-blurb-heading'];
//#endregion

const AreaListingsPageView = (props: AreaListingsPageProps) => {
  const { themeName } = useThemeContext();
  const isExpTheme = themeName && themeName !== ThemeNames.ZOOCASA;
  const [showsMLMLCreate, setShowsMLMLCreate] = useState(false);
  const [showsMLML, setShowsMLML] = useState(false);
  const showsMLMLOrPadding = themeName && themeName === ThemeNames.EXP_REALTY_US && !showsMLMLCreate && showsMLML;
  useEffect(() => {
    if (themeName === ThemeNames.EXP_REALTY_US) {
      setShowsMLMLCreate(!!Cookies.get(MLML_AGENT_COOKIE_NAME));
      setShowsMLML(!!Cookies.get(MLML_LEAD_COOKIE_NAME));
    }
  }, [themeName]);

  const {
    pagination: {
      page, count, total, size,
    },
    breadcrumbs,
    heading,
    sort,
    hasNoListingsInArea,
    noListingsInAreaMessage,
    routeMatchObject,
    isCrawler,
    showGuides,
    showDisclaimer,
    genericNamedContent: initialGenericNamedContent,
    footerData: initialFooterData,
    listings,
    areaBlurb: initialAreaBlurb,
    areaBlurbHeading: initialAreaBlurbHeading,
    showFilterDeprecationWarning = false,
    showNoImageListingsFilterWarning = false,
    listingParams,
    relatedSearchesData,    
    setPageNumber,
    onSortChanged,
    onMobileFilterButtonClick,
    onValueChange,
    onSaveSearchButtonClick,
    onLocationSearchClick,
    onMapIconClick,
    onMoreButtonClick,
    onDeprecationWarningButtonClick = () => {},
    onNoImageListingsFilterWarningClose = () => {},
  } = props;
  const { filter } = routeMatchObject;
  const guideInfoDefaultExpanded = isCrawler;
  const [isCityGuideExpanded, toggleCityGuide] = useActive(guideInfoDefaultExpanded);
  const [isInfrastructureExpanded, toggleInfrastructure] = useActive(guideInfoDefaultExpanded);
  const [isTaxGuideExpanded, toggleTaxGuide] = useActive(guideInfoDefaultExpanded);
  const providers = prioritizeFeeds([...new Set(listings.map(l => l.providerId))]);
  const [lazyLoadedGuide, setLazyLoadedGuide] = useState(false);

  const [lazyLoadedDisclaimer, setLazyLoadedDisclaimer] = useState(false);
  const [lazyLoadedFooterLink, setLazyLoadedFooterLink] = useState(false);
  const routeMatchLocationType = routeMatchObject.locationType || routeMatchObject.city && 'city' || routeMatchObject.province && 'province';
  const [footerData, setFooterData] = useState<LinkDataType[]>(initialFooterData);
  const [genericNamedContent, setGenericNamedContent] = useState(initialGenericNamedContent);
  const [areaBlurb, setAreaBlurb] = useState(initialAreaBlurb);
  const [areaBlurbHeading, setAreaBlurbHeading] = useState(initialAreaBlurbHeading);
  const generateAreaGuideTitle = () => {
    const { locationType, provinceCode } = routeMatchObject;
    if (locationType === 'province') {
      return isUsStateCode(provinceCode as ProvinceOrStateCode) ? 'State Guide' : 'Province Guide';
    } else if (locationType === 'neighbourhood') {
      return 'Neighbourhood Guide';
    } else {
      return 'City Guide';
    }
  };
  const areaGuideTitle = generateAreaGuideTitle();

  const isMobile = useIsMobile();
  const isSmallMobile = useIsSmallMobile();

  const subheading = hasNoListingsInArea ? noListingsInAreaMessage : capitalizeWords(`${formatNumber(count)}+ listings found`);
  const footerHeading = useMemo(() => {
    return isMobile ? 'Explore More Listings' : '';
  }, [isMobile]);

  const listingsGridBreadcrumbs = [...breadcrumbs];
  const listingsGridParams = {
    page: {
      number: page,
      size: size,
    },
  };
  const listingsGridMeta = {
    totalCount: count,
    totalPages: total === 200 || total === 0 ? total : total + 1,
  };

  const hasAreaBlurbText = areaBlurb ? areaBlurb.trim().length > 0 : false;
  const areaName = capitalizeWords(breadcrumbs[breadcrumbs.length - 1].name.toLowerCase());

  useEffect(() => {
    if (lazyLoadedFooterLink && !initialFooterData.length) {
      getFooterData({ ...routeMatchObject, locationType: routeMatchLocationType }, listingParams.filter.latitude, listingParams.filter.longitude, themeName)
        .then(r => setFooterData(r || []));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lazyLoadedFooterLink]);

  useEffect(() => {
    if (lazyLoadedGuide && !genericNamedContent && count > 0) {
      getAreaNamedContents(routeMatchObject).then(genericNamedContents => {
        if (genericNamedContents.length > 0) {
          const namedContent = genericNamedContents?.[0];
          const hasAreaBlurb = namedContent.content.includes('DESCRIPTION BREAK');
          const splitNamedContentData = hasAreaBlurb ? namedContent.content.split('<p>DESCRIPTION BREAK</p>') : namedContent.content;
          setAreaBlurb(hasAreaBlurb ? splitNamedContentData[0] : null);
          setGenericNamedContent(hasAreaBlurb ? splitNamedContentData[1]?.split('<p>SECTION BREAK</p>') : (splitNamedContentData as string).split('<p>SECTION BREAK</p>'));
          const provinceOrStateName: string = SanitizeName(routeMatchObject.province);
          const city: string = SanitizeName(routeMatchObject.city);
          const neighbourhood: string = SanitizeName(routeMatchObject.neighbourhood);
          setAreaBlurbHeading(hasAreaBlurb ? generateAreaBlurbHeading(city, neighbourhood, provinceOrStateName) : null);
        }
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lazyLoadedGuide]);

  return (
    <div>
      <Breadcrumbs breadcrumbs={breadcrumbs} />
      <div className={styles.component}>
        <section className={SearchNavClassName}>
          <AreaListingsPageFilters
            onLocationSearchClick={onLocationSearchClick}
            onValueChange={onValueChange}
            onSaveSearchButtonClick={onSaveSearchButtonClick}
            onMapIconClick={onMapIconClick}
            onMoreButtonClick={onMoreButtonClick}
            filters={filter as AreaListingsPageFiltersType}
            showNoImageListingsFilterWarning={showNoImageListingsFilterWarning}
            onNoImageListingsFilterWarningClose={onNoImageListingsFilterWarningClose}
          />
        </section>
        {showsMLMLOrPadding ?
          <MyLinkMyLead />
          :
          <div className={TitlePaddingClassName} />
        }
        <div className={TitleSortRowClassName}>
          <div>
            <h1 className={buildClassName(PageTitleClassName, isExpTheme && styles['exp-theme'])} data-testid={headerIds.pageHeading}>
              {heading}
            </h1>
            <h2 className={PageSubtitleClassName}>
              {subheading}
            </h2>
          </div>
          <div className={SubFilterClassName}>
            <SelectField
              className={SortByClassName}
              testId={searchFilterIds.sortByDropdown}
              ariaLabel={'Sort by dropdown'}
              value={sort}
              options={sortOptions(filter.status || AVAILABLE_STATUS)}
              onValueChange={onSortChanged}
            />
            <Button
              className={MobileFilterBtnClassName}
              theme={TERTIARY_THEME}
              Icon={FilterIcon}
              label="Filters"
              onClick={onMobileFilterButtonClick}
            />
            {showsMLMLCreate ?
              <MyLinkMyLeadCreate />
              :
              <Button
                theme={isSmallMobile ? PRIMARY_THEME : TERTIARY_THEME}
                label="Save Search"
                Icon={NotificationIcon}
                testId={searchFilterIds.saveSearchButtonMapPage}
                onClick={() => {
                  onSaveSearchButtonClick();
                  trackEvent(GTM_CLICK_AREA_PAGE_SAVE_SEARCH_BUTTON);
                }}
              />
            }
          </div>
        </div>
        <ListingsGrid
          testId={testIds.listingGrid}
          listings={listings}
          isLoading={false}
          params={listingsGridParams}
          meta={listingsGridMeta}
          breadcrumbs={listingsGridBreadcrumbs}
          setPageNumber={setPageNumber}
          listingParams={listingParams}
        />
        {showGuides &&
          <ConditionalWrapper
            condition={!guideInfoDefaultExpanded}
            wrapper={children => 
              isCrawler ?
                children : <LoadWhenVisible onValueChange={() => setLazyLoadedGuide(true)}>
                  {lazyLoadedGuide && children}
                </LoadWhenVisible>              
            }>
            <div className={GuideWrapperClassName} data-testid={testIds.areaGuides}>
              {hasAreaBlurbText &&
                <div className={AreaBlurbClassName}>
                  <h2 className={AreaBlurbHeadingClassName}>{areaBlurbHeading}</h2>
                  <div dangerouslySetInnerHTML={{ __html: areaBlurb! }}/>
                </div>
              }
              <RelatedSearches
                routeMatchObject={routeMatchObject}
                defaultExpanded={guideInfoDefaultExpanded}
                {...relatedSearchesData}
              />
              {genericNamedContent &&
                <>
                  <AreaListingsPageCollapsibleGuide
                    title={!isMobile ? areaName + ' ' + areaGuideTitle : areaGuideTitle}
                    type={CITY_GUIDE_NAMED_CONTENT_TYPE}
                    isActive={isCityGuideExpanded}
                    onCollapsibleSectionClick={toggleCityGuide}
                    genericNamedContent={genericNamedContent}
                    className={AreaGuideClassName}
                    testId={testIds.cityGuide}
                  />
                  <AreaListingsPageCollapsibleGuide
                    title={!isMobile ? areaName + ' Infrastructure' : 'Infrastructure'}
                    type={INFRASTRUCTURE_NAMED_CONTENT_TYPE}
                    isActive={isInfrastructureExpanded}
                    onCollapsibleSectionClick={toggleInfrastructure}
                    genericNamedContent={genericNamedContent}
                    className={AreaGuideClassName}
                    testId={testIds.infraGuide}
                  />
                  <AreaListingsPageCollapsibleGuide
                    title={!isMobile ? areaName + ' Tax Guide' : 'Tax Guide'}
                    type={TAX_GUIDE_NAMED_CONTENT_TYPE}
                    isActive={isTaxGuideExpanded}
                    onCollapsibleSectionClick={toggleTaxGuide}
                    genericNamedContent={genericNamedContent}
                    className={AreaGuideClassName}
                    testId={testIds.taxGuide}
                  />
                </>
              }
            </div>
          </ConditionalWrapper>
        }
        <LoadWhenVisible onValueChange={() => setLazyLoadedDisclaimer(true)}>
          {lazyLoadedDisclaimer && showDisclaimer && providers.map(p => <ProviderDisclaimer key={p} provider={p}/>)}
        </LoadWhenVisible>
      </div>
      <ConditionalWrapper
        condition={!guideInfoDefaultExpanded}
        wrapper={children =>
          <LoadWhenVisible onValueChange={()=>setLazyLoadedFooterLink(true)}>
            {lazyLoadedFooterLink && children}
          </LoadWhenVisible>
        }>
        <InternalLinks isCrawler={guideInfoDefaultExpanded} data={footerData} heading={footerHeading} />
      </ConditionalWrapper>
      {routeMatchObject.country &&
          <ProvinceCitySeoLinks country={routeMatchObject.country.toUpperCase() as CountryCode} />
      }
      {routeMatchLocationType === PROVINCE_LOCATION_TYPE &&
        <ProvinceCitySeoLinks
          country={isUsStateCode(routeMatchObject.provinceCode) ? CountryCodeList.UNITED_STATES : CountryCodeList.CANADA}
          province={routeMatchObject.provinceCode}
        />
      }
      {showFilterDeprecationWarning &&
        <SearchFilterDeprecationWarning onClose={onDeprecationWarningButtonClick}/>
      }
    </div>);
};

export default AreaListingsPageView;
