import { RouteMatchObject } from 'components/dynamic-page/route-matchers';
import { countryCodeFromProvinceOrState, ProvinceAndState } from './province_or_state';
import { capitalize } from '@zoocasa/node-kit/strings/capitalize';
import configJSON from 'config.json';
import { fetchWithRetry } from './fetchWithRetry';
import { InsightsResponse, TopCitiesResponse } from '@zoocasa/go-search';
import { SurroundingCities } from 'components/related-searches';

/**
 * Fetches a list of nearby cities for a given slug and province.
 *
 * @param {keyof typeof ProvinceAndState} province - The province code to fetch nearby cities.
 * @returns {Promise<SurroundingCities[]>} A promise resolving to an array of nearby cities, including city name, province, and slug.
 * @throws Will log an error and return an empty array if the fetch operation fails.
 */
export async function getSurroundingCities(province: keyof typeof ProvinceAndState): Promise<SurroundingCities[]> {
  const country = countryCodeFromProvinceOrState(province);
  let resp;
  const popularNearbyCitiesUrl = `${configJSON.goSearchClientSideHost}/insights/popular/${country}/${province}?limit=10`;
  try {
    const response = await fetchWithRetry(popularNearbyCitiesUrl, { method: 'GET' });

    if (!response.ok) {
      console.error('Failed to fetch popular cities: %d %s', response.status, response.statusText);
      return [];
    } 
    const content = await response.blob();
    const buffer = await content.arrayBuffer();
    const topCities = TopCitiesResponse.decode(new Uint8Array(buffer)).topCities;
    resp = await Promise.all(topCities.slice(0, 10).map(async c => {
      const cityProvinceName = c.Slug.slice(-2);
      return {
        city: c.Name,
        province: cityProvinceName,
        slug: c.Slug,
      };
    }));
    return resp;
  } catch (error: any) {
    console.error('Failed to fetch popular nearby cities: %s', error);
    return [];
  }
}

/**
 * Fetches area-specific data based on a given RouteMatchObject.
 *
 * @param {RouteMatchObject} routeMatchObject - The route match object containing location details.
 * @returns {Promise<InsightsResponse | null>} A promise resolving to the area data if available, or `null` if no data is found.
 * @throws Will log an error if the fetch operation fails.
 */
export const getAreaData = async (routeMatchObject: RouteMatchObject): Promise<InsightsResponse | null> => {
  const provinceCode = routeMatchObject.provinceCode.toUpperCase();
  const countryCode = countryCodeFromProvinceOrState(provinceCode);
  let cityCode = '';
  if (routeMatchObject.city) {
    cityCode = capitalize(routeMatchObject.city);
  }
  const areaPageUrl = `${configJSON.goSearchClientSideHost}/insights/stats/${countryCode}/${provinceCode}/${cityCode}`;
  try {
    const response = await fetchWithRetry(areaPageUrl, { method: 'GET' });
    const content = await response.blob();
    const buffer = await content.arrayBuffer();
    const formattedResponse = InsightsResponse.decode(new Uint8Array(buffer));
  
    const hasCompleteData = Object.values(formattedResponse).length;
    if (hasCompleteData) {
      return formattedResponse;
    }
  } catch (err: any) {
    console.error(err);
  }
};


/**
 * Fetches related search data including area insights and nearby cities.
 *
 * @param {RouteMatchObject} routeMatchObject - The route match object containing location details.
 * @returns {Promise<{ areaData: InsightsResponse | null; surroundingCities: SurroundingCities[] | null }>} 
 *          A promise resolving to an object containing area data and nearby cities, or `null` values if invalid.
 * @throws Will return null values if the location type is invalid or fetch operations fail.
 */
export const fetchRelatedSearchesData = async (routeMatchObject: RouteMatchObject): Promise<{
    areaData: InsightsResponse | null;
    surroundingCities: SurroundingCities[];
  }> => {
  // Check if the locationType is one of the required types
  const validLocationTypes = ['province', 'city', 'neighbourhood'];
    
  if (validLocationTypes.includes(routeMatchObject.locationType)) {    
    // Fetch area data
    const areaData = await getAreaData(routeMatchObject);
    // Fetch surrounding cities
    const surroundingCities = routeMatchObject.city && await getSurroundingCities(
      (routeMatchObject as any)?.provinceCode.toUpperCase()
    );
    return { areaData, surroundingCities };
  }
  
  // Return null or default values if locationType is invalid
  return { areaData: null, surroundingCities: []};
};