import React, { useContext, useEffect, useState } from 'react';
import dynamic from 'next/dynamic';
import { buildClassName } from 'utils/build-class-name';
import Link from 'components/link';
import { useUserContext } from 'contexts/user';
import { IModalContext, ModalContext } from 'contexts/modal';
import useOutsideClickHandler from 'hooks/use-outside-click-handler';
import MenuButton from 'components/header/zoo/menu-button';
import UserIcon from 'components/icon/user-icon';
import ThemedIcon from 'components/themed-icon';
import SearchIcon from 'components/icon/search-icon';
import DropdownArrowIcon from 'components/icon/dropdown-arrow-icon';
import dataJSON from './data.json';
import styles from './style.module.scss';
import { headerIds, testIds } from 'constants/test-constants';
import { useFeaturesContext } from 'contexts/features';
import { CountryCodeList } from 'types/countries';
import { useIsMobile } from 'hooks/use-size-class';
import { GetUserLastSearchResponse } from 'pages/api/last-search/types';
import { getUserLastSearch } from 'pages/api/last-search/lastSearchApiClient';
import { usePreferencesContext, useThemeContext } from 'contexts';
import { ThemeNames } from 'types/themes';
import Image from 'next/image';
import { getSavedSearches } from 'data/saved-searches';
import AccessibilityWidget from 'components/accessibility-widget';
import { useRouter } from 'next/router';
import Dropdown from 'components/dropdown';

import type SavedSearch from 'data/saved-searches';
import SquareSearchIcon from 'components/icon/square/search-icon';
import SquareUserIcon from 'components/icon/square/user-icon';
import SquareMenuIcon from 'components/icon/square/menu-icon';
import { AGENT_SEARCH_PATH } from 'utils/agent-endpoint';

export type PanelItem = {
  label: string;
  link?: string;
  isEmber?: boolean;
};

const Header = () => {
  const { userLinks } = dataJSON;
  const { isAuthenticated, user, siteLocation, isCrawler } = useUserContext();
  const { features, isWebView, isSearchPanelOpen, setIsSearchPanelOpen } = useFeaturesContext();
  const { openModal } = useContext(ModalContext) as IModalContext;
  const { themeName, theme } = useThemeContext();
  const { areFunctionalCookiesEnabled } = usePreferencesContext();
  const isZoocasaTenant = themeName === ThemeNames.ZOOCASA;
  const [menuDropdownIsActive, setMenuDropdownIsActive] = useState(isCrawler);
  const [userDropdownIsActive, setUserDropdownIsActive] = useState(false);
  const [saveSearchDropdownIsActive, setSaveSearchDropdownIsActive] = useState(false);
  const [countrySwitcherDropdownIsActive, setCountrySwitcherDropdownIsActive] = useState(false);
  const [savedSearches, setSavedSearches] = useState<SavedSearch[]>([]);
  const [lastSearch, setLastSearch] = useState<GetUserLastSearchResponse>();
  const [isSearchActive, setIsSearchActive] = useState(false);
  const [AdvancedSearch, setAdvancedSearch] = useState<any>();
  const [SavedSearchDropdown, setSavedSearchDropdown] = useState<any>();
  const [CountrySwitcher, setCountrySwitcher] = useState<any>();
  const [ChatWidget, setChatWidget] = useState<any>();
  const [Panel, setPanel] = useState<any>();
  const [panelAttributes, setPanelAttributes] = useState<{ isActive: boolean; items: PanelItem[]; title: string }>({
    isActive: false,
    items: [],
    title: '',
  });
  const isMobile = useIsMobile();
  const isUsSite = siteLocation === CountryCodeList.UNITED_STATES;
  const isChatWidgetActive = features.useSmartChat && !isWebView && isMobile;
  const showAccessibilityWidget = !isZoocasaTenant && areFunctionalCookiesEnabled;
  const headerLogoAriaLabel = themeName === ThemeNames.ZOOCASA ? 'Zoocasa header logo' : 'eXp Realty header logo';
  const router = useRouter();
  const isSearchPage = router.pathname && router.pathname.startsWith('/search');

  useEffect(() => {
    if (isChatWidgetActive && !ChatWidget) {
      setChatWidget(dynamic(import('components/chat-widget')));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isChatWidgetActive]);

  useEffect(() => {
    setIsSearchActive(isSearchPanelOpen);
  }, [isSearchPanelOpen]);

  useEffect(() => {
    if (isSearchActive) {
      setAdvancedSearch(dynamic(import('components/advanced-search')));
    }
  }, [isSearchActive]);

  const renderNavItems = () => {
    if (themeName === ThemeNames.ZOOCASA) {
      return (
        <>
          {isSearchPage && isMobile &&
            <span ref={searchSectionOutsideClickHandler}>
              <SearchIcon className={styles['header-icon']} onClick={toggleSearch} testId={headerIds.headerSearchIcon} />
              {isSearchActive && AdvancedSearch && (
                <div className={styles.panel}>
                  <AdvancedSearch hideSearchPanel={() => setIsSearchActive(false)} />
                </div>
              )}
            </span>
          }
          {(!isSearchPage || !isMobile) &&
            <Link href="/search" testId={headerIds.mapHeaderDesktop} className={styles['zoocasa-search-button']}>
              <SearchIcon className={styles['search-icon']} />
              Map
            </Link>
          }
          <Link href="/search?status=not-available-sold" testId={headerIds.soldPricesHeader}>Sold Prices</Link>
          {!features.usePreCon && <Link href="/sell-with-zoocasa" testId={headerIds.sellWithUsHeader}>Sell with Us</Link>}
          {isZoocasaTenant && <a href="https://www.zoocasa.com/blog/" data-testid={headerIds.marketInsightsHeader}>Market Insights</a>}
        </>
      );
    } else {
      return (
        <>
          <Link href="/search">
            <SearchIcon className={styles['header-icon']}/>
          </Link>
          <Link href="/buy">Buy</Link>
          <Link href="/sell">Sell</Link>
          <Link href={AGENT_SEARCH_PATH}>Agents</Link>
          <Link href="/explore-exp">About</Link>
        </>
      );
    }
  };

  const toggleMenuDropdown = () => {
    setMenuDropdownIsActive(prev => !prev);
  };
  const toggleUserDropdown = () => {
    setUserDropdownIsActive(prev => !prev);
  };
  const toggleSearch = () => setIsSearchActive(prev => !prev);

  const openSavedSearchDropdown = async () => {
    if (!SavedSearchDropdown) {
      setSavedSearchDropdown(dynamic(import('components/dropdown/saved-search')));
    }
    const { savedSearches } = await getSavedSearches(3, undefined, themeName as ThemeNames);
    setSavedSearches(savedSearches);
    if (user) {
      const lastSearch = await getUserLastSearch({ userId: user.id, sourceTheme: themeName });
      if (lastSearch && 'lastSearch' in lastSearch) {
        setLastSearch(lastSearch);
      }
    }
    setSaveSearchDropdownIsActive(prev => !prev);
  };
  const triggerPanel = (type: string) => {
    if (!Panel) {
      setPanel(dynamic(import('components/panel')));
    }
    if (type === 'user' && !isAuthenticated) {
      openModal('login-registration');
    } else {
      if (type === 'user') {
        setPanelAttributes({ isActive: true, items: userLinks, title: 'My Account' });
      } else {
        setPanelAttributes({ isActive: true, items: theme.menuLinks, title: 'Menu' });
      }
    }
  };

  const closePanel = () => setPanelAttributes({ isActive: false, items: [], title: '' });

  const toggleCountrySwitcher = () => {
    if (!CountrySwitcher) {
      setCountrySwitcher(dynamic(import('components/country-switcher')));
    }
    setCountrySwitcherDropdownIsActive(prev => !prev);
  };
  const CountrySwitcherComponent = () => {
    return (
      <div ref={useOutsideClickHandler(()=>setCountrySwitcherDropdownIsActive(false))}>
        <a className={styles['nav-btn']} onClick={toggleCountrySwitcher}>
          <div className={styles['country-flag']}>
            <Image
              src={isUsSite ? '/next/assets/images/us-flag.svg' : '/next/assets/images/ca-flag.svg'}
              alt="country flag"
              width={1.5}
              height={1.25}
            />
            <span>{isUsSite ? CountryCodeList.UNITED_STATES : CountryCodeList.CANADA}<DropdownArrowIcon className={styles['dropdown-arrow-icon']} /></span>
          </div>
        </a>
        {countrySwitcherDropdownIsActive && <CountrySwitcher toggleDropdown={toggleCountrySwitcher} />}
      </div>
    );
  };

  const authText = user ? user.firstName : 'Login';
  const searchSectionOutsideClickHandler = useOutsideClickHandler<HTMLDivElement>(() =>
  { if (isSearchPanelOpen) setIsSearchPanelOpen(false); });

  return (
    <div className={styles.component} data-testid={headerIds.header}>
      <div className={buildClassName(styles['layout-container'], isSearchActive && styles['expanded'], !isZoocasaTenant && styles.exp)}>
        <div className={buildClassName(styles['logo-wrapper'])} data-testid={headerIds.zoocasaHeaderLogo}>
          <Link href="/" as="/" className={styles.logo} rel="noreferrer" ariaLabel={headerLogoAriaLabel}><ThemedIcon /></Link>
        </div>
        <div className={styles.navigation}>
          {renderNavItems()}
          {isAuthenticated
            ?
            <>
              <MenuButton className={styles['nav-btn']} onClick={openSavedSearchDropdown} closeMenu={() => setSaveSearchDropdownIsActive(false)} testId={headerIds.mySearchesHeader}>
                <span>
                  My Searches
                  {saveSearchDropdownIsActive && SavedSearchDropdown &&
                    <SavedSearchDropdown savedSearches={savedSearches} lastSearch={lastSearch}/>
                  }
                  <DropdownArrowIcon className={styles['dropdown-arrow-icon']} />
                </span>
              </MenuButton>
              <MenuButton className={styles['nav-btn']} onClick={toggleUserDropdown} closeMenu={() => setUserDropdownIsActive(false)}>
                <span data-testid={headerIds.loggedInUser}><UserIcon /> {authText} {userDropdownIsActive && Dropdown && <Dropdown items={userLinks} />}</span>
              </MenuButton>
            </>
            :
            <a className={styles['nav-btn']} onClick={() => openModal('login-registration')} data-testid={headerIds.loginHeader}>
              <UserIcon /> Login
            </a>
          }
          {features.useUsListings && themeName !== ThemeNames.EXP_REALTY_CA && <CountrySwitcherComponent />}
          {showAccessibilityWidget && <AccessibilityWidget />}
          <MenuButton className={buildClassName(styles.menu, styles['nav-btn'])} testId={testIds.menuButton} onClick={toggleMenuDropdown} closeMenu={() => setMenuDropdownIsActive(false)}>
            <>Menu {menuDropdownIsActive && Dropdown && <Dropdown items={theme.menuLinks} />}</>
          </MenuButton>
        </div>
        <>
          <div className={styles['mobile-right']} ref={searchSectionOutsideClickHandler}>
            {isChatWidgetActive && ChatWidget && <ChatWidget />}
            {isSearchActive && AdvancedSearch && isMobile && (
              <div className={styles.panel}>
                <AdvancedSearch isInPanel={true} hideSearchPanel={() => setIsSearchActive(false)} testId={testIds.advancedSearchMobile} />
                <div className={styles['full-screen-search']} />
              </div>
            )}
            {isSearchPage && isMobile &&
              <div className={styles.search}>
                <SquareSearchIcon className={styles['header-icon']} onClick={toggleSearch} testId={headerIds.headerSearchIconMobile} />
              </div>
            }
            {(!isSearchPage || !isMobile) &&
              <Link href="/search" testId={headerIds.mapHeaderMobile} className={styles['zoocasa-search-button-mobile']}>
                <SearchIcon className={styles['search-icon-mobile']} />
                Map
              </Link>
            }
            <div id="panel-opener" onClick={() => triggerPanel('user')} data-testid={headerIds.loginMobile}>
              <SquareUserIcon className={buildClassName(styles['header-icon'], styles.unclickable)} />
            </div>
            {showAccessibilityWidget && <AccessibilityWidget />}
            <div id="panel-opener" onClick={() => triggerPanel('menu')} data-testid={headerIds.mobileMenu}>
              <SquareMenuIcon className={styles.unclickable} />
            </div>
          </div>
        </>
      </div>
      {Panel && <Panel isActive={panelAttributes.isActive} items={panelAttributes.items} title={panelAttributes.title} closePanel={closePanel} />}
      {menuDropdownIsActive && isCrawler && Dropdown && <div className={styles['mobile-right']}><Dropdown items={theme.menuLinks} /></div>}
    </div>
  );
};

export default Header;
