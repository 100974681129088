import React, { useCallback, useEffect, useState } from 'react';
import CheckmarkCircleIcon from 'components/icon/checkmark-circle-icon';
import ConnectionRequestIcons from '../icons';
import { trackEvent } from 'utils/google-tag-manager';
import styles from './style.module.scss';
import { listingPageModalIds } from 'constants/test-constants';
import { useModal, useSavedListingContext, useSnackbar, useThemeContext } from 'contexts';
import SaveHome from '../save-home';
import PhoneCTA from '../call-cta';
import { HIDE_SAVE_HOME_COOKIE } from 'constants/cookies';
import Cookies from 'js-cookie';
import Link from 'next/link';
import { ThemeNames } from 'types/themes';
import { buildClassName } from 'utils/build-class-name';
import { getPvrPhoneNumber } from 'utils/themes';

import type { ListingInfoType } from 'components/connection-request';

interface Props {
  isInModal?: boolean;
  listing?: ListingInfoType;
  isAuthenticated?: boolean;
}

export default function SuccessMessage({ isInModal, listing, isAuthenticated }: Props) {
  const { savedListingIDs } = useSavedListingContext();
  const { setSnackbarData } = useSnackbar();
  const { closeModal } = useModal();
  const [hasRespondedToSaveHome, setHasRespondedToSaveHome] = useState(false);
  const isFavourited = !!listing && savedListingIDs.includes(listing.id);
  const showSaveHome = listing && isAuthenticated && !isFavourited && !hasRespondedToSaveHome && !Cookies.get(HIDE_SAVE_HOME_COOKIE);
  const { themeName } = useThemeContext();
  const isExpTheme = themeName !== ThemeNames.ZOOCASA;
  const pvrPhoneNumber = getPvrPhoneNumber(themeName);

  const handleSaveHomeResponse = useCallback(() => {
    if (hasRespondedToSaveHome && isFavourited) {
      setSnackbarData(prev => ({
        ...prev,
        visible: true,
        message: <p>You&#39;ve added this property to <br/><Link href="/account/saved/favourites" onClick={closeModal}>My Favourites</Link></p>,
      }));
      isInModal && closeModal();
    }
  }, [hasRespondedToSaveHome, isFavourited, closeModal, isInModal, setSnackbarData]);

  useEffect(() => {
    handleSaveHomeResponse();
  }, [handleSaveHomeResponse]);

  if (isInModal) { // mobile view
    return (
      <div className={styles['modal-component']}>
        <ConnectionRequestIcons />
        <h1>Thank you!</h1>
        <p>We&apos;ll be in touch soon. If you&apos;re in a hurry, don&apos;t hesitate to reach out
          <a href={`tel:${pvrPhoneNumber}`} onClick={() => trackEvent('click_call')}>
            {pvrPhoneNumber}
          </a>
          .
        </p>
        <PhoneCTA />
        {showSaveHome && <SaveHome listing={listing} setHasRespondedToFavourites={setHasRespondedToSaveHome} />}
      </div>
    );
  }

  return (
    <>
      <div className={styles.component} data-testid={listingPageModalIds.successMessage}>
        <div className={styles.success}>
          <CheckmarkCircleIcon className={buildClassName(styles.checkmark, isExpTheme && styles['exp-checkmark'])} />
          <div className={styles.right}>
            <h1>Thanks!</h1>
            <p>We will follow up with you.</p>
            <PhoneCTA />
          </div>
        </div>
        {showSaveHome && <SaveHome listing={listing} setHasRespondedToFavourites={setHasRespondedToSaveHome} />}
      </div>
    </>
  );
}
