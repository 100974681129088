import { COMMERCIAL_PROPERTY_TYPE,
  DeprecatedPropertyTypeFilter, 
  FARM_PROPERTY_TYPE,
  HOUSE_ATTACHED_PROPERTY_TYPE,
  HOUSE_DETACHED_PROPERTY_TYPE,
  HOUSE_SEMIDETACHED_PROPERTY_TYPE,
  LAND_PROPERTY_TYPE,
  PropertyTypeFilter,
} from 'contexts/preferences/listing-params';
import { cloneDeep } from 'lodash';

export type BackwardCompatiblePropertyTypeFilter = PropertyTypeFilter & DeprecatedPropertyTypeFilter;
/**
 * Ensure backwards compatibility with deprecated property types. If homeType includes attached, detached, or semidetached properties it will be a migrated to house.
 * 
 * NOTE: deprecated properties are not removed from the returned PropertyTypeFilter object. If house is not set or migrated, it will be set to true by default.
 * 
 * @param propertyType The home type filter to update.
 * @returns The updated property type filter.
 */
export function ensureBackwardCompatibilityForHomeType(propertyType: PropertyTypeFilter): BackwardCompatiblePropertyTypeFilter {
  const backwardCompatiblePropertyType: BackwardCompatiblePropertyTypeFilter = cloneDeep(propertyType) as BackwardCompatiblePropertyTypeFilter;

  const deprecatedProperties: (keyof DeprecatedPropertyTypeFilter)[] = [HOUSE_DETACHED_PROPERTY_TYPE, HOUSE_SEMIDETACHED_PROPERTY_TYPE, HOUSE_ATTACHED_PROPERTY_TYPE];
  const newPropertyType: (keyof PropertyTypeFilter)[] = [LAND_PROPERTY_TYPE, COMMERCIAL_PROPERTY_TYPE, FARM_PROPERTY_TYPE];

  const hasDeprecatedProperty = deprecatedProperties.some(prop => 
    Object.prototype.hasOwnProperty.call(backwardCompatiblePropertyType, prop)
  );
  const hasNewPropertyType = newPropertyType.every(prop => 
    Object.prototype.hasOwnProperty.call(backwardCompatiblePropertyType, prop)
  );
  
  const hasHouse = Object.prototype.hasOwnProperty.call(backwardCompatiblePropertyType, 'house');
  if (hasDeprecatedProperty && !hasHouse) {
    const isAnyDeprecatedPropertyTrue = deprecatedProperties.some(prop => 
      backwardCompatiblePropertyType[prop as keyof PropertyTypeFilter]
    );
    backwardCompatiblePropertyType.house = isAnyDeprecatedPropertyTrue;
  } else if (hasHouse) {
    backwardCompatiblePropertyType.houseDetached = backwardCompatiblePropertyType.house;
    backwardCompatiblePropertyType.houseSemidetached = backwardCompatiblePropertyType.house;
    backwardCompatiblePropertyType.houseAttached = backwardCompatiblePropertyType.house;
  } else {
    backwardCompatiblePropertyType.house = true;
    backwardCompatiblePropertyType.houseDetached = true;
    backwardCompatiblePropertyType.houseSemidetached = true;
    backwardCompatiblePropertyType.houseAttached = true;
  }
    
  if (!hasNewPropertyType) {
    backwardCompatiblePropertyType.farm = false;
    backwardCompatiblePropertyType.commercial = false;
    backwardCompatiblePropertyType.land = false;
  }
  
  return backwardCompatiblePropertyType;
}