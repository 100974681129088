import React from 'react';
import { trackEvent } from 'utils/google-tag-manager';
import PhoneIcon from 'components/icon/phone-icon';
import styles from '../style.module.scss';
import { getPvrPhoneNumber } from 'utils/themes';
import { useThemeContext } from 'contexts';

export default function PhoneCTA() {
  const { themeName: tenantName } = useThemeContext();
  const number = getPvrPhoneNumber(tenantName);

  return (
    <div className={styles['phone-number-container']}>
      <a href={`tel:${number}`} className={styles['phone-number']} onClick={() => trackEvent('click_call')}>
        <PhoneIcon /> Call us {number}
      </a>
    </div>
  );
}
