export const CREA_PROVIDER_ID = 30002;

export const mlsProviderIds = {
  expportal_crmls: 31058,
  bright: 31020,
  stellar: 31018,
  expportal_fmls: 31135,
  expportal_sefmiami: 31019,
  mred: 31238,
  gamls: 31144,
  mlspin: 31245,
  expportal_ntreis: 31276,
  onekey: 31285,
  expportal_armls: 31029,
  expportal_har: 31192,
  expportal_beaches: 31042,
  nwmls: 31281,
  expportal_gsmls: 31143,
  metrolist: 31229,
  recolorado: 31324,
  northstar: 31275,
  canopy: 31060,
  smart: 31346,
  wfrmls: 31393,
  expportal_realcomp: 31312,
  realtracs: 31236,
  actris: 31036,
  mlsl: 31240,
  expportal_glvar: 31160,
  rmls: 31325,
  sdmls: 31337,
  expportal_sabor: 31336,
  expportal_njmls: 31259,
  expportal_triangle: 31383,
  expportal_now: 31241,
  maris: 31234,
  heartland: 31181,
  expportal_momls: 31248,
  expportal_prime: 31304,
  nef: 31314,
  mibor: 31232,
  metromls: 31230,
  ccomls: 31091,
  cjmls: 31073,
  rein: 31313,
  expportal_fgcmls: 31332,
  expportal_westpenn: 31402,
  bareis: 31040,
  expportal_naples: 31256,
  ires: 31198,
  expportal_gulfsouth: 31177,
  expportal_mlssaz: 31255,
  expportal_chsmls: 31082,
  cincymls: 31243,
  immls: 31199,
  expportal_hicentral: 31189,
  irm: 31197,
  cvrmls: 31078,
  mireal: 31253,
  amlsok: 31033,
  mreis: 31222,
  rimls: 31369,
  triad: 31382,
  mlstech: 31246,
  ctxmls: 31077,
  expportal_knoxville: 31209,
  expportal_spc: 31364,
  ppar: 31298,
  glaor: 31162,
  sfar: 31338,
  expportal_galmls: 31151,
  carmls: 31095,
  hcmlsnj: 31193,
  gprmls: 31171,
  ccarsc: 31085,
  scwmls: 31352,
  ccaormls: 31094,
  globalmls: 31146,
  ggar: 31159,
  gaar: 31361,
  expportal_ecar: 31109,
  bnymls: 31054,
  dayton: 31099,
  maar: 31227,
  valley: 31391,
  nnrmls: 31272,
  fresno: 31140,
  gsbor: 31356,
  lbar: 31050,
  gbraor: 31154,
  nysamls: 31261,
  gniaor: 31165,
  palmettomls: 31292,
  sjsrmls: 31359,
  glvr: 31161,
  mrm: 31249,
  nwabor: 31277,
  rmlsalliance: 31328,
  mvmls: 31225,
  br: 31039,
  dbaaor: 31098,
  expportal_wvmls: 31405,
  gepar: 31157,
  saor: 31367,
  gavaor: 31152,
  dmaaria: 31101,
  expportal_gcar: 31156,
  wuar: 31401,
  cciaor: 31061,
  baor: 31038,
  pensacola: 31296,
  simls: 31368,
  sckm: 31351,
  amls: 31025,
  coar: 31242,
  cdamls: 31086,
  prescott: 31303,
  noris: 31282,
  smlcorp: 31342,
  his: 31179,
  ccaor: 31096,
  tvarmls: 31377,
  wcbr: 31395,
  expportal_gtar: 31170,
  mlsunited: 31247,
  cpaor: 31075,
  gaaor: 31153,
  hhmls: 31254,
  expportal_captech: 31064,
  nkmls: 31271,
  gcmls: 31176,
  rvar: 31329,
  maui: 31323,
  cmcaor: 31063,
  sbmls: 31340,
  maarmls: 31250,
  raoa: 31315,
  tcmls: 31385,
  pcbrut: 31294,
  nglrmls: 31270,
  expportal_lubbock: 31220,
  gsm: 31168,
  cren: 31087,
  bscm: 31045,
  wristinc: 31400,
  bcsreg: 31056,
  iaor: 31200,
  caarmls: 31081,
  gmaor: 31164,
  sptbgmls: 31366,
  waor: 31392,
  nsbcrmls: 31274,
  prmls: 31288,
  greenwich: 31175,
  kvmls: 31207,
  pmar: 31300,
  hernando: 31183,
  paor: 31305,
  agm: 31032,
  naaor: 31269,
  sjcbor: 31370,
  expportal_barmls: 31047,
  rairc: 31322,
  expportal_clmls: 31034,
  sfmls: 31341,
  wardex: 31399,
  obmls: 31286,
  hlartx: 31185,
  craar: 31068,
  rase: 31320,
  tsbor: 31375,
  michric: 31233,
  fmaar: 31133,
  svvar: 31344,
  gjmls: 31150,
  snrmls: 31347,
  shasta: 31345,
  ranww: 31278,
  columbiabor: 31089,
  expportal_martincounty: 31226,
  raocc: 31321,
  saormls: 31374,
  lvamls: 31221,
  leeco: 31215,
  rockford: 31330,
  sira: 31355,
  lvaor: 31218,
  cbor: 31092,
  lsar: 31212,
  vail: 31390,
  expportal_negbor: 31265,
  lcrmls: 31219,
  hcaor: 31184,
  ucmls: 31387,
  ncwvrein: 31263,
  permianbasin: 31297,
  yuma: 31409,
  lhaor_mls: 31211,
  cwbr: 31080,
  ogaor: 31287,
  nlaor: 31267,
  bcbor: 31044,
  gebr: 31158,
  middlega: 31235,
  fkbor: 31137,
  neirbr: 31266,
  aiken: 31023,
  wmls: 31408,
  cillbor: 31072,
  tborwy: 31378,
  laar: 31213,
  summit: 31373,
  rask: 31318,
  wmaor: 31404,
  snmmls: 31357,
  beaumont_bor: 31043,
  haor: 31180,
  wamls: 31396,
  rcar: 31327,
  neabor: 31264,
  swvar: 31362,
  aincaor: 31027,
  cgnd: 31090,
  chcbor: 31071,
  firelands: 31134,
  tcar: 31386,
  cwtar: 31079,
  haaor: 31178,
  expportal_cgmls: 31070,
  bhaor: 31049,
  miaaor: 31224,
  ucar: 31388,
  kwar: 31208,
  pwar: 31299,
  acvmls: 31022,
  bmbor: 31048,
  alamance: 31024,
  nwiamls: 31279,
  gsmar: 31172,
  caor: 31372,
  raosm: 31319,
  yakima: 31244,
  upaor: 31389,
  expportal_fhaor: 31136,
  ecrbor: 31107,
  ahar: 31026,
  mtraor: 31252,
  sanangelo_aor: 31335,
  gnmls: 31283,
  hsbor: 31191,
  hokaor: 31182,
  jcabor: 31205,
  csvbor: 31076,
  nobor: 31268,
  vmls: 31358,
  mbor: 31223,
  newcanbor: 31258,
  lbor: 31214,
  highlands: 31186,
  raofgc: 31316,
  gcbor: 31148,
  expportal_lcar: 31210,
  nflmls: 31273,
  calaveras: 31057,
  gora: 31166,
  expportal_ccar: 31066,
  texarkana: 31379,
  svar: 31343,
  cmls: 31093,
  stillwater_bor: 31371,
  dglcbor: 31102,
  rbor: 31334,
  habormls: 31187,
  rbr: 31333,
  taosmls: 31376,
  iglbor: 31201,
  gwcaor: 31147,
  csar: 31059,
  clatsop: 31083,
  ivbor: 31195,
  sjar: 31339,
  aabor: 31030,
  raor: 31311,
  garmls: 31141,
  wbr: 31403,
  elko: 31106,
  spibr: 31360,
  mountainlakes: 31251,
  samls: 31353,
  acmls: 31035,
  gibor: 31149,
  blbor: 31037,
  cjaor: 31084,
  scaor_va: 31350,
  gpabr: 31167,
  mtcbor: 31228,
  prcmls: 31295,
  ridgecrestaor: 31326,
  par: 31290,
  brazoria: 31053,
  ashbor: 31031,
  aberdeen: 31021,
  hccbor: 31190,
  dublin: 31104,
  tcbor: 31380,
  rcbr: 31310,
  bvp: 31384,
  gpar: 31174,
  hcmlsia: 31194,
  mmls: 31239,
  brunswick: 31055,
  cfrmls: 31062,
  ccamls: 31067,
  fdmls: 31138,
  jbornc: 31204,
  kutmls: 31206,
  nrrar: 31257,
  rmaar: 31331,
  gflr: 31349,
  tiar: 31381,
  wbcmls: 31394,
  wls: 31398,
  wilson: 31406,
};

// providers who require immediate attribution declaration i.e. before the key facts section
export const courtesyProviderIds = [
  mlsProviderIds.expportal_prime,
] as const;