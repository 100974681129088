import { COMMERCIAL_TARGETED_URL_PROPERTY_TYPE,
  CONDO_TARGETED_URL_PROPERTY_TYPE, 
  FARMS_TARGETED_URL_PROPERTY_TYPE,
  LAND_TARGETED_URL_PROPERTY_TYPE,
  TOWNHOUSE_TARGETED_URL_PROPERTY_TYPE,
  getBedOrBathSelection, 
  getOtherSecondaryFilterSelection, 
  isForSaleOrRent, 
} from 'utils/listing-query-helper';
import { generateRouteMatchObjectFromPath, generateLocationFromRouteMatchObject } from 'components/dynamic-page/route-matchers';
import { capitalizeWords, capitalizeSentences, capitalize } from '@zoocasa/node-kit/strings/capitalize';
import { deDasherize } from '@zoocasa/node-kit/strings/de-dasherize';
import formatNumber from 'utils/format-number';
import { getTargetedUrlPropertyTypeSelection } from 'utils/listing-query-helper';
import { averageListingPrice } from 'data/listing';
import { NOT_AVAILABLE_SOLD_STATUS } from 'contexts/preferences/listing-params/types';

import type { RouteMatchObject } from 'components/dynamic-page/route-matchers';
import type { Breadcrumb } from 'components/breadcrumbs';
import type { ListingParams } from 'contexts/preferences/listing-params/types';
import type { HeadDataType } from 'components/head-data';
import type { ThemeConfig } from 'themes';

interface Item<T = any> {
  [key: string]: T;
}

export default function buildHeadTags<T extends Item>(theme: ThemeConfig, path: string, listingsCount: number, lowestPrice: number | null, highestPrice: number | null, breadcrumbs: Breadcrumb[], listings: T[], params: ListingParams): HeadDataType {
  const routeMatchObject = generateRouteMatchObjectFromPath(path);
  const { province } = routeMatchObject;
  const isAlbertaHousesPage = province === 'alberta' && path.includes('/houses');
  let canonicalUrl = `${theme.schemaUrl}/${path}`;
  // SEO experiment: on the /houses subvariant on Alberta area pages, make the canonical url point back to the default area page
  if (isAlbertaHousesPage) {
    canonicalUrl = canonicalUrl.replace('/houses', '');
  }

  // If the area page subLocation includes a number & contains /filters, route-matchers detects this as an address
  // This then doesn't return the correct routeMatchObject data
  // ex; /vancouver-bc-real-estate/solo-3-cirrus/filter?bedrooms=1%2B&rental=false&status=available&detached=true&semidetached=true&attached=true
  if (!('filter' in routeMatchObject)) {
    routeMatchObject.filter = params.filter;
  }

  const isAvailable = routeMatchObject.filter.status === 'available';

  const breadcrumbItemList = breadcrumbs.map(({ name, link }, index) => ({
    '@type': 'ListItem',
    position: index + 1,
    item: {
      '@id': link,
      name,
    },
  }));

  return {
    canonicalUrl: canonicalUrl,
    title: getTitle(theme, routeMatchObject, isAvailable),
    metaDescription: getMetaDescription(routeMatchObject, isAvailable, listingsCount),
    schema: [
      {
        '@context': 'https://schema.org',
        '@type': 'BreadcrumbList',
        itemListElement: breadcrumbItemList,
      },
      {
        '@context': 'https://schema.org',
        '@type': 'Product',
        name: getProductSchemaName(routeMatchObject),
        offers: {
          '@type': 'AggregateOffer',
          highPrice: highestPrice || 0,
          lowPrice: lowestPrice || 0,
          priceCurrency: 'CAD',
        },
      },
      getFaqSchema(listingsCount, listings, routeMatchObject, params),
    ],
  };
}

export function getProductSchemaName(routeMatchObject: RouteMatchObject) {
  const location = generateLocationFromRouteMatchObject(routeMatchObject);
  const homeTypeSelection = getTargetedUrlPropertyTypeSelection(routeMatchObject.filter.homeType);
  const homeTypeValue = homeTypeSelection !== 'Homes' ? ` & ${homeTypeSelection}` : '';
  return `${location} Real Estate${homeTypeValue}`;
}

export function getFaqSchema<T extends Item>(listingsCount: number, listings: T[], routeMatchObject: RouteMatchObject, params: ListingParams) {

  const isRental = routeMatchObject.isRental;
  const { province, city } = routeMatchObject;
  const isCanadaPage = !province && !city;
  const place = capitalizeSentences(areaName(routeMatchObject));
  const cityAndProvince = isCanadaPage ? 'Canada' : city ? place + ', ' + capitalize(province) : capitalize(province);
  const filters = params.filter;
  const houses = filters.homeType.house;
  const townhouses = filters.homeType.townhouse;
  const condos = filters.homeType.condo;
  let homeType = 'homes';
  if (houses && !townhouses && !condos) {
    homeType = 'houses';
  }
  else if (!houses && townhouses && !condos) {
    homeType = 'townhouses';
  }
  else if (!houses && !townhouses && condos) {
    homeType = 'condos';
  }

  const averagePrice = houses && townhouses && condos ? averageListingPrice(listings) : null;
  const averageHousePrice = houses ? averageListingPrice(listings, 'house') : null;
  const averageTownhousePrice = townhouses ? averageListingPrice(listings, 'townhouse') : null;
  const averageCondoPrice = condos ? averageListingPrice(listings, 'condo') : null;

  const numberOfHomes = {
    '@type': 'Question',
    name: `How many ${homeType} are there for ${isRental ? 'rent' : 'sale'} in ${isCanadaPage ? 'Canada' : place}?`,
    acceptedAnswer: {
      '@type': 'Answer',
      text: `There are a total of ${formatNumber(listingsCount)} ${homeType} for ${isRental ? 'rent' : 'sale'} in ${isCanadaPage ? 'Canada' : place}.`,
    },
  };
  const listingPrice = averagePrice ? {
    '@type': 'Question',
    name: `What is the average listing price of a home for ${isRental ? 'rent' : 'sale'} in ${isCanadaPage ? 'Canada' : place}?`,
    acceptedAnswer: {
      '@type': 'Answer',
      text: `The average listing price of all home types in ${cityAndProvince} is $${formatNumber(averagePrice)}.`,
    },
  } : null;
  const housePrice = averageHousePrice && houses ? {
    '@type': 'Question',
    name: `How much does a house cost in ${isCanadaPage ? 'Canada' : place}?`,
    acceptedAnswer: {
      '@type': 'Answer',
      text: `The average price of a house for ${isRental ? 'rent' : 'sale'} in ${cityAndProvince} is $${formatNumber(averageHousePrice)}.`,
    },
  } : null;

  const townhousePrice = averageTownhousePrice && townhouses ? {
    '@type': 'Question',
    name: `How much does a townhouse cost in ${isCanadaPage ? 'Canada' : place}?`,
    acceptedAnswer: {
      '@type': 'Answer',
      text: `The average price of a townhouse for ${isRental ? 'rent' : 'sale'} in ${cityAndProvince} is $${formatNumber(averageTownhousePrice)}.`,
    },
  } : null;

  const condoPrice = averageCondoPrice && condos ? {
    '@type': 'Question',
    name: `How much does a condo cost in ${isCanadaPage ? 'Canada' : place}?`,
    acceptedAnswer: {
      '@type': 'Answer',
      text: `The average price of a condo for ${isRental ? 'rent' : 'sale'} in ${cityAndProvince} is $${formatNumber(averageCondoPrice)}.`,
    },
  } : null;

  const questions = [numberOfHomes, listingPrice, housePrice, townhousePrice, condoPrice].filter(n => n);
  return {
    '@context': 'https://schema.org',
    '@type': 'FAQPage',
    mainEntity: questions,
  };
}

export function areaName(routeMatchObject: RouteMatchObject) {
  const { street, province, city, neighbourhood } = routeMatchObject || {};
  const area = deDasherize(neighbourhood || city || province || '');
  return street ? `${deDasherize(street)}, ${area}` : area;
}

export function getTitle(theme: ThemeConfig, routeMatchObject: RouteMatchObject, isAvailable: boolean) {
  let title = '';
  const notHomeTypePage = !routeMatchObject || !routeMatchObject.homeType || routeMatchObject.homeType === 'real-estate';
  const secondaryFilter = routeMatchObject.secondaryFilter || routeMatchObject.secondaryFilterNeighbourhood || routeMatchObject.secondaryFilterStreet;
  const homeTypeSelection = getTargetedUrlPropertyTypeSelection(routeMatchObject.filter.homeType);
  const bedOrBathSelection = getBedOrBathSelection(secondaryFilter);
  const otherSecondaryFilterSelection = getOtherSecondaryFilterSelection(secondaryFilter);
  if (isAvailable) {
    const location = generateLocationFromRouteMatchObject(routeMatchObject);
    const { locationType, province, city, street } = routeMatchObject;
    const isAlberta = province === 'alberta';
    const forSaleOrRent = isForSaleOrRent(routeMatchObject.filter.rental);
    title = `${bedOrBathSelection}${location} Homes & Real Estate ${forSaleOrRent}${otherSecondaryFilterSelection}`;
    if (notHomeTypePage) {
      if (location === 'Canada') {
        title = `${bedOrBathSelection}Canada Real Estate & Homes ${forSaleOrRent}${otherSecondaryFilterSelection}`;
      } else if (locationType === 'street') {
        title = `${bedOrBathSelection}${capitalizeWords(deDasherize(street))}, ${deDasherize(capitalizeWords(city))} Real Estate${isAlberta ? ' & Homes' : ''} ${forSaleOrRent}${otherSecondaryFilterSelection}`;
      } else {
        title = `${bedOrBathSelection}${location} Real Estate${isAlberta ? ' & Homes' : ''} ${forSaleOrRent}${otherSecondaryFilterSelection}`;
      }
    } else {
      title = `${bedOrBathSelection}${location} ${homeTypeSelection} ${forSaleOrRent}${otherSecondaryFilterSelection}`;
    }
  } else {
    const area = areaName(routeMatchObject);
    const statusIsSold = routeMatchObject.filter.status === 'not-available-sold';
    const isRental = routeMatchObject.filter.rental;
    const soldTitle = `Recently ${isRental ? 'Rented' : 'Sold'} ${area} ${homeTypeSelection} & Real Estate`;
    const pastTitle = area + ' Past Listings';
    title = `${bedOrBathSelection}${statusIsSold ? soldTitle : pastTitle}${otherSecondaryFilterSelection}`;
  }
  return capitalizeWords(title) + ` | ${theme.name}`;
}

export function getMetaDescription(routeMatchObject: RouteMatchObject, isAvailable: boolean, listingsCount: number) {
  let metaDescription = '';
  const { locationType, province, city } = routeMatchObject;
  const propertyTypeSelection = getTargetedUrlPropertyTypeSelection(routeMatchObject.filter.homeType);

  if (isAvailable) {
    const location = generateLocationFromRouteMatchObject(routeMatchObject);
    let locationFull = location;
    if (locationType === 'city') {
      locationFull = capitalizeWords(deDasherize(`${city}, ${province}`));
    }
    const forSaleOrRent = isForSaleOrRent(routeMatchObject.filter.rental);
    const prefix = locationType === 'street' ? 'on' : 'in';
    const notHomeTypePage = !routeMatchObject || !routeMatchObject.homeType || routeMatchObject.homeType === 'real-estate';
    if (notHomeTypePage) { // General area page e.g. /toronto-on-real-estate
      metaDescription = `View real estate listings and photos of ${formatNumber(listingsCount)} homes and properties listed ${forSaleOrRent.toLowerCase()} ${prefix} ${locationFull}. `;
      metaDescription += `Find the perfect ${location} house, condo, townhouse for sale.`;
    } else { // Specific type e.g. /toronto-on-real-estate/condos
      let homeTypeName1 = 'home';
      let homeTypeName2 = 'house';
      let homeTypeName3 = 'houses';
      if (propertyTypeSelection === TOWNHOUSE_TARGETED_URL_PROPERTY_TYPE) {
        homeTypeName1 = 'townhouse';
        homeTypeName2 = 'townhouse';
        homeTypeName3 = 'townhomes';
      } else if (propertyTypeSelection === CONDO_TARGETED_URL_PROPERTY_TYPE) {
        homeTypeName1 = 'condo';
        homeTypeName2 = 'condo';
        homeTypeName3 = 'condos';
      } else if (propertyTypeSelection === FARMS_TARGETED_URL_PROPERTY_TYPE) {
        homeTypeName1 = 'farm';
        homeTypeName2 = 'farm';
        homeTypeName3 = 'farms';
      } else if (propertyTypeSelection === COMMERCIAL_TARGETED_URL_PROPERTY_TYPE) {
        homeTypeName1 = 'commercial';
        homeTypeName2 = 'commercial';
        homeTypeName3 = 'commercial';
      } else if (propertyTypeSelection === LAND_TARGETED_URL_PROPERTY_TYPE) {
        homeTypeName1 = 'land';
        homeTypeName2 = 'land';
        homeTypeName3 = 'land';
      }
      const isRentalText = routeMatchObject.filter.rental ? ' for rent.' : '.';
      
      metaDescription = `View ${formatNumber(listingsCount)} ${homeTypeName1} listings ${prefix} ${locationFull}${isRentalText} `;
      metaDescription += `Find a ${homeTypeName2} ${prefix} ${location} with the latest mls listings. `;
      metaDescription += `Browse photos of ${location} ${homeTypeName3} ${forSaleOrRent.toLowerCase()}.`;
    }
  } else {
    const area = areaName(routeMatchObject);
    const statusIsSold = routeMatchObject.filter.status === NOT_AVAILABLE_SOLD_STATUS;
    let type = statusIsSold ? 'past sold' : 'removed and expired';
    let prefix = statusIsSold ? 'sold price, list price, sold date,' : 'list price';
    if (routeMatchObject.filter.rental) {
      type = 'past rental';
      prefix = 'rental price';
    }
    metaDescription = `Search for all ${type} property listings in ${capitalizeWords(area)}. Find the ${prefix} and pictures for all recent listings.`;
  }
  return capitalizeSentences(metaDescription);
}
