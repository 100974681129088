import { PartialDeep } from 'type-fest';
import Geohash from 'latlon-geohash';
import {
  AreaPageListing_ListingType,
  AreaPageListing_Status,
  Geohash as LatLonAsGeohash,
  MapPageFilter,
  PropertyCategories,
  Range_Date,
  Range_Float,
  Range_Integer,
  Style,
  TypeOfProperty,
} from '@zoocasa/go-search';
import {
  NOT_AVAILABLE_OTHER_STATUS,
  NOT_AVAILABLE_SOLD_STATUS,
  NOT_AVAILABLE_STATUS,
} from 'contexts/preferences/listing-params';
import { GeoSearchFilter } from './types';
import { Precision } from 'components/search-es/geo_helper';
import { Bounds } from 'components/search-es/types';

//#region Types
export type GeoFilterStrategy = (bounds: Bounds, precision: Precision, filter: PartialDeep<GeoSearchFilter>) => MapPageFilter;
//#endregion

function sharedFilterLogic(bounds: Bounds, precision: Precision, filter: PartialDeep<GeoSearchFilter>): MapPageFilter {
  let statusId: AreaPageListing_Status;

  if (filter.status === NOT_AVAILABLE_SOLD_STATUS) {
    statusId = AreaPageListing_Status.NotAvailableSold;
  } else if (filter.status === NOT_AVAILABLE_STATUS) {
    statusId = AreaPageListing_Status.NotAvailable;
  } else if (filter.status === NOT_AVAILABLE_OTHER_STATUS) {
    statusId = AreaPageListing_Status.NotAvailableOther;
  } else {
    statusId = AreaPageListing_Status.Available;
  }

  let price: Range_Float | undefined;
  if (filter.priceMax || filter.priceMin) {
    price = Range_Float.fromPartial({ gte: filter.priceMin || undefined, lte: filter.priceMax || undefined });
  }

  const maxOldestDate = new Date(new Date().setFullYear(new Date().getFullYear() - 1)).toISOString();
  const listedDate: Range_Date = Range_Date.fromPartial({
    gte: filter.listedSince ? new Date(filter.listedSince) : new Date(maxOldestDate),
    lte: filter.listedTo ? new Date(filter.listedTo) : undefined });

  let squareFeet: Range_Float | undefined;
  if (filter.sqftMin || filter.sqftMax){
    squareFeet = Range_Float.fromPartial({ gte:filter.sqftMin || undefined, lte: filter.sqftMax || undefined });
  }

  let hasGarage:boolean | false = false;
  if (filter.garage == true){
    hasGarage = filter.garage;
  }

  let hasPool:boolean | false = false;
  if (filter.pool == true){
    hasPool = filter.pool;
  }

  let hasOpenHouse:boolean | false = false;
  if (filter.openHouse == true){
    hasOpenHouse = filter.openHouse;
  }

  let hasWaterFront:boolean | false = false;
  if (filter.waterfront == true){
    hasWaterFront = filter.waterfront;
  }

  let hasFirePlace:boolean | false = false;
  if (filter.fireplace == true){
    hasFirePlace = filter.fireplace;
  }

  let hasImage: boolean | false = false;
  if (filter.hasImage == true){
    hasImage = filter.hasImage;
  }

  let hasLocker:boolean | false = false;
  if (filter?.additional?.condoOrTownhouse?.locker == 'yes'){
    hasLocker = true;
  }

  let maintenanceFee: number | undefined = undefined;
  if (filter?.additional?.condoOrTownhouse?.maintenanceFee){
    maintenanceFee = filter.additional.condoOrTownhouse.maintenanceFee;
  }

  const filterArg: MapPageFilter= MapPageFilter.fromPartial({
    listingType: filter.rental ? AreaPageListing_ListingType.rent : AreaPageListing_ListingType.buy,
    statusId,
    price: price,
    squareFeet: squareFeet,
    hasGarage: hasGarage,
    hasPool: hasPool,
    hasOpenHouse: hasOpenHouse,
    hasWaterFront: hasWaterFront,
    hasFirePlace: hasFirePlace,
    hasLocker: hasLocker,
    maintenance: maintenanceFee,
    listedDate,
    precision,
    bedroomsExact: undefined,
    bedroomsRange: undefined,
    bathroomsExact: undefined,
    bathroomsRange: undefined,
    parkingExact: undefined,
    parkingRange: undefined,
    countryCode: filter.countryCode,
    hasImage,
  });

  if (filter?.bedrooms?.endsWith('+')) {
    filterArg.bedroomsRange = Range_Integer.fromPartial({ gte: Number(filter.bedrooms?.replace('+', '')) });
  } else if (filter?.bedrooms) {
    filterArg.bedroomsExact = Number(filter.bedrooms);
  } else {
    filterArg.bedroomsRange = Range_Integer.fromPartial({ gte: 0 });
  }

  if (filter?.bathrooms?.endsWith('+')) {
    filterArg.bathroomsRange = Range_Integer.fromPartial({ gte: Number(filter.bathrooms?.replace('+', '')) });
  } else if (filter?.bathrooms) {
    filterArg.bathroomsExact = Number(filter.bathrooms);
  } else {
    filterArg.bathroomsRange = Range_Integer.fromPartial({ gte: 0 });
  }

  if (filter?.parkingSpaces?.endsWith('+')) {
    filterArg.parkingRange = Range_Integer.fromPartial({ gte: Number(filter.parkingSpaces?.replace('+', '')) });
  } else if (filter?.parkingSpaces) {
    filterArg.parkingExact = Number(filter.parkingSpaces);
  } else {
    filterArg.parkingRange = Range_Integer.fromPartial({ gte: 0 });
  }

  // Calculate corner geohashes
  const topLeftGeohash = precision === 1 ? 'b' : Geohash.encode(bounds.north, bounds.west, precision);
  const bottomRightGeohash = precision === 1 ? 'd' : Geohash.encode(bounds.south, bounds.east, precision);

  filterArg.latLonAsGeohash = LatLonAsGeohash.fromPartial({
    topLeft: topLeftGeohash,
    bottomRight: bottomRightGeohash,
  });

  return filterArg;
}

export const DefaultFilterStrategy: GeoFilterStrategy = (bounds, precision, filter) => {
  const baseFilter = sharedFilterLogic(bounds, precision, filter);

  const categories = Object.entries(filter?.homeType || {}).reduce((acc, [key, value]) => {
    if (value) {
      const category = key === 'house' ? 'House'
        : key === 'townhouse' ? 'Townhouse'
          : key === 'condo' ? 'Condo'
            : key === 'farm' ? 'Farm'
              : key === 'land' ? 'Land'
                : key === 'commercial' ? 'Commercial'
                  : key === 'houseDetached' ? 'House'
                    : key === 'houseSemidetached' ? 'House'
                      : key === 'houseAttached' ? 'House'
                        : 'Other';
      acc.push(category);
    }
    return acc;
  }, [] as string[]);

  const allPossibleCategories = ['House', 'Townhouse', 'Condo', 'Farm', 'Land', 'Commercial'];
  if (categories.length === 0 || allPossibleCategories.every(category => categories.includes(category))) {
    categories.push('Other');
  }

  const typeOfProperty = categories.reduce((acc, category) => {
    const categoryData = filter.rental
      ? PropertyCategories[category].rent
      : PropertyCategories[category].sale;

    acc.push(...categoryData.map(obj => ({
      propertyTypes: obj.types,
      propertySubTypes: obj.subTypes,
    })));
    return acc;
  }, [] as TypeOfProperty[]);

  return {
    ...baseFilter,
    typeOfProperty,
  };
};

export const LegacyFilterStrategy: GeoFilterStrategy = (bounds, precision, filter) => {
  const baseFilter = sharedFilterLogic(bounds, precision, filter);

  const styles = [];
  if (filter?.homeType?.condo) {
    styles.push(Style.condo_highrise, Style.condo_lowrise, Style.condo_new_development, Style.condo_other);
  }

  if (filter?.homeType?.townhouse) {
    styles.push(Style.townhouse);
  }

  if (filter?.homeType?.house) {
    styles.push(Style.house_attached, Style.house_detached, Style.house_semidetached, Style.house_new_development, Style.house_other, Style.multifamily_other);
  }

  if (!filter?.homeType?.condo && !filter?.homeType?.townhouse && !filter?.homeType?.house) {
    styles.push(Style.vacant_land, Style.other_unspecified);
  }

  if (filter?.homeType?.house && filter?.homeType?.condo && filter?.homeType?.townhouse) {
    styles.push(Style.All);
  }

  return {
    ...baseFilter,
    styles,
  };
};
