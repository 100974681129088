import React from 'react';
import styles from './style.module.scss';
import { searchFilterIds } from 'constants/test-constants';
import type { PropertyType, PropertyTypeFilter } from 'contexts/preferences/listing-params/types';
import { trackEvent } from 'utils/google-tag-manager';
import { MORE_FILTERS_OPTIONS_TO_GTM_EVENT } from 'constants/area-map-page-filters'; 
import Toggle from 'components/control/toggle';
import { buildClassName } from 'utils/build-class-name';
import { PropertyTypeOption, legacyPropertyTypeOptions } from 'utils/select-options';
import { 
  CommercialIcon,
  CondoIcon,
  FarmIcon,
  HouseIcon,
  LandIcon,
  TownhouseIcon,
} from 'components/icon/filters';

//#region Types
interface FilterDropdownPropertyTypeProps {
  propertyTypeList?: PropertyTypeOption[];
  checkboxNamePrefix?: string;
  currentHomeTypeFilter: PropertyTypeFilter;
  isInDropdown?: boolean;
  onValueChange: (homeType: PropertyTypeFilter) => void;
}
//#endregion

export default function FilterDropdownPropertyType({ checkboxNamePrefix = '', currentHomeTypeFilter, onValueChange, isInDropdown = true, propertyTypeList = legacyPropertyTypeOptions }: FilterDropdownPropertyTypeProps) {
  const handleClick = (value: PropertyType) => {
    const newValue = !currentHomeTypeFilter[value];

    onValueChange({
      ...currentHomeTypeFilter,
      [value]: newValue,
    });
    
    if (newValue) {
      trackEvent(MORE_FILTERS_OPTIONS_TO_GTM_EVENT[value]);
    }
  };

  const isActive = (value: PropertyType) => {
    return currentHomeTypeFilter[value];
  };

  const mapIcon = (label: string) => {
    switch (label.toLowerCase()) {
    case 'house':
      return HouseIcon;
    case 'townhouse':
      return TownhouseIcon;
    case 'condo':
      return CondoIcon;
    case 'land':
      return LandIcon;
    case 'commercial':
      return CommercialIcon;
    case 'farm':
      return FarmIcon;
    default:
      return null; 
    }
  };


  const isHouse = (filter: PropertyTypeFilter) => filter.house === true;

  return (
    <div className={buildClassName(styles.component, !isInDropdown && styles['no-padding'])}>
      {propertyTypeList.map((item, index) => {
        let indentedOptions;
        if (item.options) {
          indentedOptions = (
            <div key={index} className={`${styles.indented} ${isHouse(currentHomeTypeFilter) ? styles.shown : styles.hidden}`}>
              {item.options.map(({ label, value }) => (
                <Toggle
                  key={label}
                  label={label}
                  name={checkboxNamePrefix + label}
                  value={isActive(value)}
                  onClick={() => handleClick(value)}
                  testId={label + 'CheckboxOption'}
                  isFullWidth
                  Icon={mapIcon(label)}
                />
              ))}
            </div>
          );
        }
        return (
          <div key={index}>
            <Toggle
              label={item.label}
              name={checkboxNamePrefix + item.label}
              value={isActive(item.value)}
              onClick={() => handleClick(item.value)}
              testId={item.label + searchFilterIds.checkboxOption}
              isFullWidth
              Icon={mapIcon(item.label)}
            />
            {indentedOptions}
          </div>
        );
      })}
    </div>
  );
}
