import React, { useContext, useEffect, useState } from 'react';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { buildClassName } from 'utils/build-class-name';
import Link from 'components/link';
import { useUserContext } from 'contexts/user';
import { IModalContext, ModalContext } from 'contexts/modal';
import MenuButton from 'components/header/exp/menu-button';
import ThemedIcon from 'components/themed-icon';
import dataJSON from './data.json';
import FakeAdvancedSearch from 'components/advanced-search/fake-search';
import styles from './style.module.scss';
import { headerIds, testIds } from 'constants/test-constants';
import { useIsDesktop, useIsMobile } from 'hooks/use-size-class';
import { useFeaturesContext, useThemeContext } from 'contexts';
import { useExperiment, useGrowthBook } from '@growthbook/growthbook-react';
import Button from 'components/control/button';
import UserIcon from 'components/icon/user-icon';
import { PATHS_WITH_FLOATING_HEADERS, PATHS_WITH_SCROLLED_HEADERS } from 'constants/headers';
import AdvancedSearch from 'components/advanced-search';
import SquareSearchIcon from 'components/icon/square/search-icon';
import useOutsideClickHandler from 'hooks/use-outside-click-handler';
import { usePreferencesContext } from 'contexts/preferences';
import { useTrackExpOnlyEvents } from 'hooks/use-track-exp-only-events';
import { GTM_EXP_FIND_YOUR_HOME_CLICK_HOME_PAGE, GTM_EXP_FIND_YOUR_HOME_CLICK_UNIVERSAL } from 'constants/events';
import AdvancedSearchV2 from 'components/advanced-search-v2';
import { ThemeNames } from 'types/themes';
import { ExperimentVariants } from 'utils/types';
import { trackEvent } from 'utils/google-tag-manager';
import Dropdown from 'components/dropdown';

const Header = () => {
  const { userLinks } = dataJSON;
  const { isAuthenticated, user, isCrawler } = useUserContext();
  const { openModal } = useContext(ModalContext) as IModalContext;
  const { isSearchPanelOpen, setIsSearchPanelOpen } = useFeaturesContext();
  const { themeName: tenantName, theme } = useThemeContext();
  const { setIsMapSearchOpen, isMapSearchOpen } = usePreferencesContext();
  const [menuDropdownIsActive, setMenuDropdownIsActive] = useState(isCrawler);
  const [userDropdownIsActive, setUserDropdownIsActive] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const isMobile = useIsMobile();
  const router = useRouter();
  const { trackExpEvent } = useTrackExpOnlyEvents();
  const isFloating = PATHS_WITH_FLOATING_HEADERS.includes(router.pathname);
  const useScrolledHeader = PATHS_WITH_SCROLLED_HEADERS.includes(router.pathname);

  const isAgentSearch = router.pathname === '/agents-search';
  const isHomePage = router.pathname === '/';
  const isSearchPage = router.pathname && router.pathname.startsWith('/search');
  const isExpUsTenant = tenantName === ThemeNames.EXP_REALTY_US;
  const isExpCaTenant = tenantName === ThemeNames.EXP_REALTY_CA;
  const isAreaPage = router.pathname == '/[...dynamic]';
  const searchSectionOutsideClickHandler = useOutsideClickHandler<HTMLDivElement>(() => { setIsSearchPanelOpen(false); setIsMapSearchOpen(false); });
  const isJoinExp = tenantName === ThemeNames.EXP_REALTY_US && router.pathname === '/join-exp';
  const isDesktop = useIsDesktop();
  const abSoldListingExperiment = useExperiment({ key: 'exp-home-page-searchbar-ab', variations: [0, 1], weights: [.5, .5]});
  const isExperimentActive = abSoldListingExperiment.variationId === ExperimentVariants.B;
  const showSearchV2 = isHomePage && isDesktop && isExperimentActive && (isExpCaTenant || isExpUsTenant);

  const toggleMenuDropdown = () => {
    setMenuDropdownIsActive(prev => !prev);
  };
  const toggleUserDropdown = () => {
    setUserDropdownIsActive(prev => !prev);
  };

  const generateAuthUserLabel = () => {
    if (user) {
      if (user.firstName || user.lastName) {
        return `${(user?.firstName || '').slice(0, 1)}${(user?.lastName || '').slice(0, 1)}`;
      }
      return user?.email?.slice(0, 1);
    }
    return 'Log In';
  };
  const authUserLabel = generateAuthUserLabel();

  const handleScroll = () => setIsScrolled(window.scrollY > 40);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const toggleSearch = () => {
    if (isMapSearchOpen) {
      setIsMapSearchOpen(false);
    } else {
      const newValue = !isSearchPanelOpen;
      setIsSearchPanelOpen(newValue);
    }
  };

  const SearchOrAreaPageButton = () => (
    <div className={styles.search} ref={searchSectionOutsideClickHandler}>
      <Button
        Icon={isMobile && SquareSearchIcon}
        onClick={toggleSearch}
        className={buildClassName(styles.auth, isMobile && styles.user, (isSearchPanelOpen || isMapSearchOpen) && styles.active)}
      />
      {isMapSearchOpen && !isSearchPanelOpen && isExpUsTenant && (
        <div className={buildClassName(styles.panel, styles['no-background-color'])} onClick={() => { setIsSearchPanelOpen(true); setIsMapSearchOpen(false); }}>
          <FakeAdvancedSearch />
        </div>)}
      {isSearchPanelOpen && AdvancedSearch && (
        <div className={styles.panel}>
          <AdvancedSearch isInPanel={true} isFilterButtonHidden={true} hideSearchPanel={() => setIsSearchPanelOpen(false)} />
          <div className={styles['full-screen-search']} />
        </div>
      )}
    </div>
  );

  const JoinExpContent = () => (
    <Link href="https://join.exprealty.com/contact/" className={styles['find-your-home']}>
      <Button tabIndex={-1} label="Contact Us" className={styles.search} />
    </Link>
  );
  
  const DefaultContent = () => (
    <Link onClick={() => {
      if (isHomePage) {
        trackExpEvent(GTM_EXP_FIND_YOUR_HOME_CLICK_HOME_PAGE);
        trackExpEvent(GTM_EXP_FIND_YOUR_HOME_CLICK_UNIVERSAL);  
      } else {
        trackExpEvent(GTM_EXP_FIND_YOUR_HOME_CLICK_UNIVERSAL);  
      }
    }} 
    href={isExpUsTenant ? '/search?openSearch=true' : '/search'} className={styles['find-your-home']}>
      <Button tabIndex={-1} label="Find Your Home" className={styles.search} />
    </Link>
  );

  const HeaderButton = () => {
    if ((isSearchPage || isAreaPage) && isMobile) {
      return <SearchOrAreaPageButton />;
    } else if (isJoinExp) {
      return <JoinExpContent />;
    } else {
      return <DefaultContent />;
    }
  };

  useEffect(() => {
    if (!showSearchV2 && isHomePage) {
      trackEvent('XpA_HomePButView');
    }
  }, [showSearchV2, isHomePage]);


  return (
    <div className={buildClassName(styles.component, isFloating && styles.home, isAgentSearch && styles['agent-search'], (isScrolled || useScrolledHeader) && styles.scrolled)} data-testid={headerIds.header}>
      <div className={buildClassName(styles['layout-container'])}>
        <div className={buildClassName(styles['logo-wrapper'])} data-testid={headerIds.zoocasaHeaderLogo}>
          <Link href="/" as="/" className={styles.logo} rel="noreferrer"><ThemedIcon /></Link>
        </div>
        <div className={styles.navigation}>
          {showSearchV2 ? <AdvancedSearchV2 /> : <HeaderButton />}

          {isAuthenticated
            ?
            <MenuButton onClick={toggleUserDropdown} closeMenu={() => setUserDropdownIsActive(false)}>
              <span data-testid={headerIds.loggedInUser}>{authUserLabel} {userDropdownIsActive && Dropdown && <Dropdown items={userLinks} />}</span>
            </MenuButton>
            :
            <Button
              label={!isMobile ? authUserLabel : ''}
              Icon={isMobile && UserIcon}
              onClick={() => openModal('login-registration')}
              className={buildClassName(styles.auth, isMobile && styles.user)}
            />
          }
          <MenuButton className={styles.menu} testId={testIds.menuButton} onClick={toggleMenuDropdown} closeMenu={() => setMenuDropdownIsActive(false)}>
            <>
              <div className={styles['bar-container']} id="menu">
                <span className={buildClassName(styles.bar, menuDropdownIsActive && styles.x)} />
                <span className={buildClassName(styles.bar, menuDropdownIsActive && styles.x)} />
              </div>
              {menuDropdownIsActive && Dropdown && <Dropdown items={theme.menuLinks} />}
            </>
          </MenuButton>
        </div>
      </div>
    </div>
  );
};

export default Header;
