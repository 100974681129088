import React, { useContext } from 'react';
import { IModalContext, ModalContext } from 'contexts/modal';
import ConnectionRequest from 'components/connection-request';
import Button from 'components/control/button';
import PhoneIcon from 'components/icon/phone-icon';
import { trackEvent } from 'utils/google-tag-manager';
import styles from './style.module.scss';
import { GTM_CLICK_ADDRESS_PAGE_PHOTO_GALLERY_PVR_CTA } from 'constants/events';

import type Listing from 'data/listing';
import { useThemeContext } from 'contexts';
import { getPvrPhoneNumber } from 'utils/themes';
interface Props {
  listing: Listing;
  isModal?: boolean;
}

export default function ImageGalleryCta({ listing, isModal = false }: Props) {
  const { openModal } = useContext(ModalContext) as IModalContext;
  const { themeName: tenantName } = useThemeContext();
  const number = getPvrPhoneNumber(tenantName);

  const clickCTA = () => {
    trackEvent('photo-gallery-cta');
    if (!isModal) {
      trackEvent(GTM_CLICK_ADDRESS_PAGE_PHOTO_GALLERY_PVR_CTA);
    }
    openModal('connection-request', { listing });
  };

  return (
    <div className={styles.component}>
      <section className={styles.desktop}>
        <ConnectionRequest listing={listing} isAddressPage={!isModal} isOnPhotoGallery={true} />
      </section>
      <section className={styles.mobile}>
        <div className={styles.title}>
          Like what you see?
        </div>
        <Button
          onClick={clickCTA}
          label="Take a Tour"
          theme='primary'
          className={styles['cta-button']}
        />
        <section className={styles['call-us']}>
          <PhoneIcon className={styles.phone} /> Call us {number}
        </section>
      </section>
    </div>
  );
}
