import type { Filter, ListingParams } from 'contexts/preferences/listing-params/types';

export const torontoLocation = {
  latitude: 43.653226,
  longitude: -79.3831843,
};

export const tampaLocation = {
  latitude: 27.9506,
  longitude: -82.4572,
};

export const centerUsLocation = {
  latitude: 37.09024,
  longitude: -95.712891,
};

const DEFAULT_LISTING_PARAMS_FILTER_SHARED= Object.freeze<Filter>({
  rental: false,
  status: 'available',
  homeType: {
    house: true,
    townhouse: true,
    condo: true,
    land: true,
    commercial: true,
    farm: true,
  },
  priceMin: null,
  priceMax: null,
  listedSince: null,
  listedTo: null,
  bedrooms: '0+',
  sqftMin: null,
  sqftMax: null,
  bathrooms: '1+',
  parkingSpaces: '0+',
  openHouse: false,
  garage: false,
  pool: false,
  fireplace: false,
  waterfront: false,
  additional: {
    condoOrTownhouse: {
      locker: 'any',
      maintenanceFee: null,
    },
  },
  boundary: null,
  providerId: null,
  slug: '',
  latitude: 0,
  longitude: 0,
  zoom: 14,
  areaName: '',
  hasImage: true,
});


const DEFAULT_LISTING_PARAMS = Object.freeze<ListingParams>({
  sort: '-date',
  filter: {
    ...DEFAULT_LISTING_PARAMS_FILTER_SHARED,
    slug: 'toronto-on',
    ...torontoLocation,
    areaName: 'Toronto, ON',
  },
});

export const DEFAULT_LISTING_PARAMS_US = Object.freeze<ListingParams>({
  sort: '-date',
  filter: {
    ...DEFAULT_LISTING_PARAMS_FILTER_SHARED,
    slug: 'tampa-fl',
    areaName: 'Tampa, FL',
    ...tampaLocation,
  },
});

export const DEFAULT_LISTING_PARAMS_WHOLE_US = Object.freeze<ListingParams>({
  sort: '-date',
  filter: {
    ...DEFAULT_LISTING_PARAMS_FILTER_SHARED,
    slug: '',
    areaName: 'USA',
    ...centerUsLocation,
    zoom: 5,
  },
});

export default DEFAULT_LISTING_PARAMS;
