import React, { useEffect, useState } from 'react';
import Link from 'components/link';
import { generatePathFromRouteMatchObject, RouteMatchObject } from 'components/dynamic-page/route-matchers';
import { capitalizeSentences } from '@zoocasa/node-kit/strings/capitalize';
import { deDasherize } from '@zoocasa/node-kit/strings/de-dasherize';
import formatCurrency from 'utils/format-currency';
import CollapsibleSection from 'components/collapsible-section';
import styles from './style.module.scss';
import useActive from 'hooks/use-active';
import SingleDataCard from './single-data-card';
import AreaData from './area-data';
import { InsightsResponse } from '@zoocasa/go-search';
import { testIds } from 'constants/test-constants';
import { useIsMobile } from 'hooks/use-size-class';
import replaceFrenchAccents from 'utils/replace-french-accents';
import { fetchRelatedSearchesData } from 'utils/related-searches';


interface Props {
  routeMatchObject: RouteMatchObject;
  defaultExpanded: boolean;
  areaData: InsightsResponse;
  surroundingCities: SurroundingCities[];
}



export default function RelatedSearches({ 
  routeMatchObject, 
  defaultExpanded, 
  areaData: initialAreaData, 
  surroundingCities: initialSurroundingCities, 
}: Props) {
  const [areaData, setAreaData] = useState<InsightsResponse>(initialAreaData);
  const [surroundingCities, setSurroundingCities] = useState<SurroundingCities[]>(initialSurroundingCities);
  const [isBuyingGuideExpanded, toggleBuyingGuide] = useActive(defaultExpanded);  
  const isMobile = useIsMobile();
  const hasData = !areaData ? false :
    (areaData?.topNeighbourhoods[0]?.name) || (areaData?.topCities[0]?.name) ||
    (areaData?.MedianPurchasePrice!==0 || areaData?.MedianRentPrice!==0 || areaData?.DaysOnMarket!==0 || areaData?.SellListRatio!==0) ||
    (areaData?.AvgTotalPrice!==0 || areaData?.AvgHousePrice!==0 || areaData?.AvgTownhousePrice!==0 || areaData?.AvgCondoPrice!==0);
  const { province, city } = routeMatchObject;

  const areaName = () => capitalizeSentences(deDasherize(city || province));

  useEffect(() => {
    const hasInitialData = initialAreaData !== null && initialSurroundingCities?.length > 0;
    if (hasInitialData) return;
    if (!routeMatchObject) return;
    fetchRelatedSearchesData(routeMatchObject).then(res => {
      setAreaData(res?.areaData ?? null);
      setSurroundingCities(res?.surroundingCities ?? []);
    }).catch(err => {
      console.error('Failed to fetch nearby cities and area data: ', err);
    });  
  }, [routeMatchObject, initialAreaData, initialSurroundingCities]);

  return (
    hasData ?
      <CollapsibleSection title={!isMobile ? areaName() + ' Buying Overview' : 'Buying Overview'} isActive={isBuyingGuideExpanded} onClick={toggleBuyingGuide} className={styles['related-searches']} testId={testIds.buyingGuide}>
        {areaData && isBuyingGuideExpanded && <RelatedSearchesContent areaData={areaData} surroundingCities={surroundingCities} routeMatchObject={routeMatchObject} areaName={areaName()} />}
      </CollapsibleSection>
      : null
  );
}

export type SurroundingCities = {
  city: string;
  province: string;
  slug: string;
};

interface RelatedSearchesContentProps {
  routeMatchObject: RouteMatchObject;
  areaData: InsightsResponse;
  surroundingCities?: SurroundingCities[];
  areaName: string;
}

function RelatedSearchesContent({ areaData, surroundingCities, routeMatchObject, areaName }: RelatedSearchesContentProps) {
  const {
    AvgCondoPrice,
    AvgHousePrice,
    AvgTownhousePrice,
    AvgTotalPrice,
    AvgRentalPrice,
    TotalRentals,
    DaysOnMarket,
    MedianPurchasePrice,
    MedianRentPrice,
    SellListRatio,
    topCities,
    topNeighbourhoods,
  } = areaData;
  const { city } = routeMatchObject;
  const hasMedianData = MedianPurchasePrice!==0 || MedianRentPrice!==0 || DaysOnMarket!==0 || SellListRatio!==0;
  const hasAvgData = AvgTotalPrice!==0 || AvgHousePrice!==0 || AvgTownhousePrice!==0 || AvgCondoPrice!==0;
  
  const linksForCitiesOrNeighborhoods = (cityList: InsightsResponse['topCities']) => {
    const formatCityNameToSlug = (name: string) => {
      // remove all punctuations then replace all spaces with dashes
      let formattedSlug = name?.toLowerCase().replaceAll(/[.,/#!$%^&*;:{}=_`~()]/g, '').replaceAll(' ', '-');
      // replace french punctuation and accents
      formattedSlug = formattedSlug?.replace("'", '-');
      formattedSlug = replaceFrenchAccents(formattedSlug);
      return formattedSlug;
    };

    const generatePath = (name: string) => {
      if (city) { // generating a neighbourhood url for this city
        return `${formatCityNameToSlug(routeMatchObject.city)}-${routeMatchObject.provinceCode}-real-estate/${formatCityNameToSlug(name)}`;
      } else { // generating a city url for this province
        return `${formatCityNameToSlug(name)}-${routeMatchObject.provinceCode}-real-estate`;
      }
    };

    if (cityList) {
      const children = cityList.map(child => {
        const { name, count } = child;
        return {
          name: name,
          path: generatePath(name),
          listingCount: count,
          isLast: false,
        };
      });
      if (children.length) {
        children[children.length - 1].isLast = true;
      }
      return children;
    }
    return [];
  };

  const generatePopularCitiesOrNeighbourhoods = (
    cityWithLinks: Array<{name: string; path: string; listingCount: number; isLast: boolean}>
  ) => (
    cityWithLinks.map((child, index) => {
      const content = [];
      if (index !== 0) {
        content.push(`, ${child.isLast ? 'and' : ''} `);
      }
      content.push(<Link href="[...dynamic]" as={child.path} key={index}>{child.name}</Link>);
      content.push(`, with ${child.listingCount} active real estate listings`);
      return content;
    })
  );


  const soldPath = () => {
    return generatePathFromRouteMatchObject(routeMatchObject, { listingStatus: 'sold-listings' });
  };

  const expiredPath = () => {
    return generatePathFromRouteMatchObject(routeMatchObject, { listingStatus: 'past-listings' });
  };

  const generateSurroundingCities = () => {
    return (
      surroundingCities?.map((city, index) => {
        const content = [];
        if (index === (surroundingCities.length - 1)) {
          content.push(', and ');
        } else if (index !== 0) {
          content.push(', ');
        }
        content.push(<Link href="[...dynamic]" as={`${city.slug}-real-estate`} key={index}>{city.city}</Link>);
        return content;
      })
    );
  };

  return (
    <div className={styles.component}>
      {(topCities[0]?.name || topNeighbourhoods[0]?.name || hasMedianData) &&
        <div className={styles['section-container']}>
          <h3>Buying a Property in {areaName}</h3>

          {(topCities[0]?.name && !topNeighbourhoods[0]?.name) &&
            <p>
              Popular cities include&nbsp;
              {generatePopularCitiesOrNeighbourhoods(linksForCitiesOrNeighborhoods(topCities))}.
            </p>
          }

          {(topNeighbourhoods[0]?.name && !topCities[0]?.name) &&
            <p>
              Popular neighborhoods include&nbsp;
              {generatePopularCitiesOrNeighbourhoods(linksForCitiesOrNeighborhoods(topNeighbourhoods))}.
            </p>
          }

          {hasMedianData &&
            <div className={styles['single-data-card-section']}>
              <SingleDataCard fieldName="Median Price" value={MedianPurchasePrice} isCurrency hasSuffix />
              <SingleDataCard fieldName="Median Rent" value={MedianRentPrice} isCurrency/>
              <SingleDataCard fieldName="Avg. Days for Sale" value={DaysOnMarket}/>
              <SingleDataCard fieldName="Sell/List Price Ratio" value={SellListRatio} isRatio/>
            </div>
          }
        </div>
      }

      {hasAvgData &&
          <div className={styles['section-container']}>
            <h3>Current Real Estate for sale in {areaName}:</h3>
            <AreaData data={areaData}/>
            {AvgTotalPrice &&
                <p>
                    The average listing price in {areaName} for all home types is {formatCurrency(AvgTotalPrice)}.
                </p>
            }
            <p>
                  For past listings, search our&nbsp;
              <Link href='[...dynamic]' as={soldPath()}>
                {areaName + ' sold listings'}
              </Link>
              {' and '}
              <Link href='[...dynamic]' as={expiredPath()}>
                {areaName + ' expired listings'}
              </Link>
              {'. '}
            </p>
          </div>
      }

      {(surroundingCities && !!surroundingCities.length) &&
          <div className={styles['section-container']}>
            <h3>Surrounding Cities</h3>
            <p>
              There are many popular cities surrounding {areaName}, including&nbsp;
              {generateSurroundingCities()}
              {'. '}
              {(!!TotalRentals && !!AvgRentalPrice) &&
                `They offer a variety of home prices and unique areas to live.
                ${areaName} also has many options for renters with ${TotalRentals} active rental listings and an average lease price of ${formatCurrency(AvgRentalPrice)}.`
              }
            </p>
          </div>
      }
    </div>
  );
}
