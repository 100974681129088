export enum ExperimentVariants {
  A = 0,
  B = 1,
  C = 2,
}

export type NewListingParamsType = {
  sort?: string;
  filter?: {
    listedSince?: string;
    listedTo?: string;
  };
  fields?: {
    listings?: string;
  };
} & PaginationParams;

export type TAdId = 'ad-top' | 'ad-top-mobile' | 'ad-top-popup' | 'ad-top-popup-mobile' | 'ad-bottom' | 'ad-bottom-mobile' | 'ad-side' | 'ad-side-popup';

export type PaginationParams = {
  page: {
    number: number;
    size: number;
  };
  setPage?: (number?: number, size?: number) => void;
};

export type Screen = { width?: number };

export type SavedListingsParams = {
  page: {
      size: number;
      number: number;
  };
  sort: string;
  filter: {
      status: string;
  };
};

export type Meta = {
  totalPages: number;
  totalCount: number;
  pageNumber: number;
  pageSize: number;
};
