import { SortBy, AreaPageListingResponse, MapPageListingResponse } from '@zoocasa/go-search';
import fetchAreaPageListings from './area/fetcher';
import { DefaultFilterStrategy, LegacyFilterStrategy, AreaFilterStrategy } from './area/area-filter-strategy';
import { getGoSearchHost, isServerSide } from 'utils/host-config';
import { SearchByAreaFilterType } from './area/types';
import { Bounds } from 'components/search-es/types';
import { Precision } from 'components/search-es/geo_helper';
import { MapPageBoundarySearchResponse } from '@zoocasa/go-search/dist/map_page';
import { GeoFilterStrategy, DefaultFilterStrategy as GeoDefaultFilterStrategy, LegacyFilterStrategy as GeoLegacyFilterStrategy } from './map/map-filter-strategy';
import { GeoSearchFilter } from './map/types';
import { MAP_PAGE_CONSTANTS } from 'constants/pagination';
import { fetchClusters, fetchListings } from './map/fetcher';
import { ThemeName } from 'themes';

//#region Types

/** 
 * SearchApi interface type that represents the api methods available for the search service
 */
export type SearchApiType = {

  /** 
   * Fetches all listings for an area page using the given parameters.
   * 
   * @param country The country to search within
   * @param province The province to search within
   * @param city The city to search within
   * @param neighbourhood The neighbourhood to search within
   * @param filter The filter to apply to the search
   * @param tenant The theme name is passed for limiting listings shown
   * @param sortBy The sort order of the results
   * @param page The page number to return
   * @param size  An optional number of results to return. Will use default value if not provided
   */
  searchByArea(country: string, province: string, city: string, neighbourhood: string, filter: SearchByAreaFilterType, tenant: ThemeName, sortBy: SortBy, page: number, size?: number): Promise<AreaPageListingResponse>;

  /** 
   * Searches for clusters within a boundary using the given precision and filter. Results are sorted by the given sortBy parameter.
   * 
   * @param bounds The bounding box to search within
   * @param precision The precision of the search. See [Geohash](https://en.wikipedia.org/wiki/Geohash#Digits_and_precision_in_km) for more details.
   * @param filter The filter to apply to the search
   * @param sortBy The sort order of the results
   * @param signal An optional AbortSignal to cancel the request
   * @param size  An optional number of results to return. Will use default value if not provided
   */
  searchClustersInBoundary(bounds: Bounds, precision: Precision, filter: GeoSearchFilter, sortBy: SortBy, signal?: AbortSignal, size?: number): Promise<MapPageListingResponse>;

  /** 
   * Searches for listings within a boundary using the given precision, filter, page, and sortBy parameters. 
   * 
   * @param bounds The bounding box to search within
   * @param precision The precision of the search. See [Geohash](https://en.wikipedia.org/wiki/Geohash#Digits_and_precision_in_km) for more details.
   * @param page The page number to return
   * @param filter The filter to apply to the search
   * @param sortBy The sort order of the results
   * @param signal An optional AbortSignal to cancel the request
   * @param size  An optional  number of results to return. Will use default value if not provided
   */
  searchListingsInBoundary(bounds: Bounds, precision: Precision, page: string, filter: GeoSearchFilter, sortBy: SortBy, signal?: AbortSignal, size?: number): Promise<MapPageBoundarySearchResponse>;
};
//#endregion

export class SearchApi implements SearchApiType { 
  private host: string;
  private areaFilterStrategy: AreaFilterStrategy;
  private geoFilterStrategy: GeoFilterStrategy;

  private constructor(host: string, filterStrategy: AreaFilterStrategy, geoFilterStrategy: GeoFilterStrategy) {
    this.host = host;
    this.areaFilterStrategy = filterStrategy;
    this.geoFilterStrategy = geoFilterStrategy;
  }

  /** 
   * Creates a new SearchApi instance with the given host and filter strategy. Legacy search filter does not include farm, land, or commercial properties.
   * 
   * @param host The host to use for the search api. Defaults to the result of getGoSearchHost(isServerSide())
   * @param isLegacy Whether to use the legacy search filter. Legacy search filter does not include farm, land, or commercial properties. Defaults to false
   */
  static create(host: string = getGoSearchHost(isServerSide()), isLegacy: boolean = false): SearchApi {
    const filterStrategy = isLegacy ? LegacyFilterStrategy : DefaultFilterStrategy;
    const geoFilterStrategy = isLegacy ? GeoLegacyFilterStrategy : GeoDefaultFilterStrategy;
    return new SearchApi(host, filterStrategy, geoFilterStrategy);
  }

  /** 
   * Fetches all listings for an area page using the given parameters.
   * 
   * @param country The country to search within
   * @param province The province to search within
   * @param city The city to search within
   * @param neighbourhood The neighbourhood to search within
   * @param filter The filter to apply to the search
   * @param sortBy The sort order of the results. Defaults to date descending if not provided
   * @param page The page number to return. Defaults to 1 if not provided
   * @param size The number of results to return. Defaults to AREA_PAGE_CONSTANTS.DEFAULT_PAGE_SIZE if not provided
   * @param tenant Determine the Tenant of the host in order to serve the correct data
   */
  async searchByArea(country: string, province: string, city: string, neighbourhood: string, filter: SearchByAreaFilterType, tenant: ThemeName, sortBy: SortBy = SortBy.DateDesc, page: number = 1, size?: number): Promise<AreaPageListingResponse> {
    const apiFilter = this.areaFilterStrategy(filter);
    return await fetchAreaPageListings(country, province, city, neighbourhood, sortBy, apiFilter, page, size, this.host, tenant);
  }

  /** 
   * Searches for clusters within a boundary using the given precision and filter. Results are sorted by the given sortBy parameter and defaults to date descending if not provided.
   * 
   * @param bounds The bounding box to search within
   * @param precision The precision of the search. See [Geohash](https://en.wikipedia.org/wiki/Geohash#Digits_and_precision_in_km) for more details.
   * @param filter The filter to apply to the search
   * @param sortBy The sort order of the results. Defaults to date descending if not provided
   * @param signal An optional AbortSignal to cancel the request
   * @param size The number of results to return. Defaults to MAP_PAGE_CONSTANTS.DEFAULT_CLUSTER_DATA_SIZE if not provided
   */
  async searchClustersInBoundary(bounds: Bounds, precision: Precision, filter: GeoSearchFilter, sortBy: SortBy = SortBy.DateDesc, signal?: AbortSignal, size: number = MAP_PAGE_CONSTANTS.DEFAULT_CLUSTER_DATA_SIZE): Promise<MapPageListingResponse> {
    const apiFilter = this.geoFilterStrategy(bounds, precision, filter);
    return await fetchClusters(apiFilter, sortBy, signal, size, this.host);
  }

  /** 
   * Searches for listings within a boundary using the given precision, filter, page, and sortBy parameters. 
   * Results are sorted by the given sortBy parameter and defaults to date descending if not provided.
   * 
   * @param bounds The bounding box to search within
   * @param precision The precision of the search. See [Geohash](https://en.wikipedia.org/wiki/Geohash#Digits_and_precision_in_km) for more details.
   * @param page The page number to return
   * @param filter The filter to apply to the search
   * @param sortBy The sort order of the results. Defaults to date descending if not provided
   * @param signal An optional AbortSignal to cancel the request
   * @param size The number of results to return. Defaults to MAP_PAGE_CONSTANTS.DEFAULT_CLUSTER_DATA_SIZE if not provided
   */
  async searchListingsInBoundary(bounds: Bounds, precision: Precision, page: string, filter: GeoSearchFilter, sortBy: SortBy = SortBy.DateDesc, signal?: AbortSignal, size: number = MAP_PAGE_CONSTANTS.DEFAULT_CLUSTER_DATA_SIZE): Promise<MapPageBoundarySearchResponse> {
    const apiFilter = this.geoFilterStrategy(bounds, precision, filter);
    return await fetchListings(apiFilter, sortBy, page, signal, size, this.host);
  }
}