import defaultListingParams from 'contexts/preferences/listing-params/defaults';
import { getJSON } from 'utils/cookies';
import { storageKey } from 'contexts/preferences/listing-params/utils';
import { DEFAULT_EXPIRE_DAYS } from 'constants/cookies';
import Cookies from 'js-cookie';
import { cloneDeep } from 'lodash';
import deepmerge from 'deepmerge';
import { DEFAULT_HAS_IMAGE_VALUE } from 'contexts/preferences/listing-params';
import {
  HOUSE_ATTACHED_PROPERTY_TYPE,
  HOUSE_DETACHED_PROPERTY_TYPE,
  HOUSE_SEMIDETACHED_PROPERTY_TYPE,
} from 'contexts/preferences/listing-params/types';

import type {
  CondoOrTownhouseAdditionalFilter,
  Filter,
  PropertyTypeFilter,
  Sort,
  ListingParams,
  ListingParamsMethods,
  DeprecatedPropertyTypeFilter,
} from 'contexts/preferences/listing-params/types';

//#region Types

/**
 * The ListingParamsImpl class is responsible for managing the listing parameters object used throughout the application as a way to persist the user's filter and sort preferences.
 *
 * This implementation ensures backward compatibility with deprecated property types and updates the "listing-params" cookie whenever the object is updated.
 */
export default class ListingParamsImpl implements ListingParams, ListingParamsMethods {
  sort: Sort;
  filter: Filter;

  constructor(listingParams: Record<string, unknown>) {
    Object.assign(this, listingParams);
  }

  setSort = (sort: Sort) => {
    this.sort = sort;
    updateListingParamsCookie(this);
  };

  /**
   * Set the filter for the listing params Object. The listing params object will be serialized and stored in the "listing-params" cookie.
   *
   * NOTE: This method ensures backward compatibility with deprecated property types.
   *
   * @param filter The filter to set.
   */
  setFilter = (filter: Filter) => {
    const originalFilter = cloneDeep(filter);
    if (originalFilter?.homeType) {
      originalFilter.homeType = ensureBackwardCompatibilityForHomeType(originalFilter.homeType);
    }
    this.filter = cloneDeep(deepmerge(defaultListingParams.filter, originalFilter));
    updateListingParamsCookie(this);
  };

  setSlug = (slug: Filter['slug']) => {
    this.filter.slug = slug;
    updateListingParamsCookie(this);
  };

  setLongitude = (longitude: Filter['longitude']) => {
    this.filter.longitude = longitude;
    updateListingParamsCookie(this);
  };

  setLatitude = (latitude: Filter['latitude']) => {
    this.filter.latitude = latitude;
    updateListingParamsCookie(this);
  };

  setZoom = (zoom: Filter['zoom']) => {
    if (zoom > 1 && zoom < 23) {
      this.filter.zoom = Math.round(zoom);
    }
    updateListingParamsCookie(this);
  };

  setStatus = (status: Filter['status']) => {
    this.filter.status = status;
    updateListingParamsCookie(this);
  };

  setRental = (rental: Filter['rental']) => {
    this.filter.rental = rental;
    this.filter.priceMin = 0;
    this.filter.priceMax = null;
  };

  setBedrooms = (bedrooms: Filter['bedrooms']) => {
    this.filter.bedrooms = bedrooms;
    updateListingParamsCookie(this);
  };

  setBathrooms = (bathrooms: Filter['bathrooms']) => {
    this.filter.bathrooms = bathrooms;
    updateListingParamsCookie(this);
  };

  setSqftMin = (sqftMin: Filter['sqftMin']) => {
    this.filter.sqftMin = sqftMin;
    updateListingParamsCookie(this);
  };

  setSqftMax = (sqftMax: Filter['sqftMax']) => {
    this.filter.sqftMax = sqftMax;
    updateListingParamsCookie(this);
  };

  setParkingSpaces = (parkingSpaces: Filter['parkingSpaces']) => {
    this.filter.parkingSpaces = parkingSpaces;
    updateListingParamsCookie(this);
  };

  setListedSince = (listedSince: Filter['listedSince']) => {
    this.filter.listedSince = listedSince;
    updateListingParamsCookie(this);
  };

  setListedTo = (listedTo: Filter['listedTo']) => {
    this.filter.listedTo = listedTo;
    updateListingParamsCookie(this);
  };

  setLocker = (locker: CondoOrTownhouseAdditionalFilter['locker'] ) => {
    this.filter.additional.condoOrTownhouse.locker = locker;
    updateListingParamsCookie(this);
  };

  setMaintenanceFee = (maintenanceFee: CondoOrTownhouseAdditionalFilter['maintenanceFee']) => {
    this.filter.additional.condoOrTownhouse.maintenanceFee = maintenanceFee;
    updateListingParamsCookie(this);
  };

  setPriceMin = (priceMin: Filter['priceMin']) => {
    this.filter.priceMin = priceMin;
    updateListingParamsCookie(this);
  };

  setPriceMax = (priceMax: Filter['priceMax']) => {
    this.filter.priceMax = priceMax;
    updateListingParamsCookie(this);
  };

  setPrice(priceMin: Filter['priceMin'], priceMax: Filter['priceMax']) {
    this.filter.priceMin = priceMin;
    this.filter.priceMax = priceMax;
    updateListingParamsCookie(this);
  }

  setHomeType = (homeType: PropertyTypeFilter) => {
    this.filter.homeType = { ...homeType };
    updateListingParamsCookie(this);
  };

  setBoundary = (boundary: Filter['boundary']) => {
    this.filter.boundary = boundary;
    updateListingParamsCookie(this);
  };

  setAreaName = (areaName: Filter['areaName']) => {
    this.filter.areaName = areaName;
    updateListingParamsCookie(this);
  };

  setHasImage = (hasImage: Filter['hasImage']) => {
    this.filter.hasImage = hasImage || DEFAULT_HAS_IMAGE_VALUE;
    updateListingParamsCookie(this);
  };
}

//#endregion

//#region Public Functions

/**
 * Create a new ListingParams object from the given parameters.
 * Backwards compatibility is ensured by ensuring that deprecated property types are migrated.
 *
 * NOTE: If no parameters are provided, the function will attempt to retrieve the parameters from "listing-params" cookie.
 *
 * @param params The parameters to use for the listing params.
 * @returns A new ListingParams object cloned from the default listing params and merged with the provided parameters.
 */
export function createListingParams(params?: ListingParams): ListingParams & ListingParamsMethods {
  if (!params) {
    params = getJSON(Cookies.get(storageKey));
  }

  if (params?.filter?.homeType) {
    params.filter.homeType = ensureBackwardCompatibilityForHomeType(params.filter.homeType);
  }

  const properties = cloneDeep(deepmerge(defaultListingParams, params));
  const listingParams = new ListingParamsImpl(properties);
  updateListingParamsCookie(listingParams);
  return listingParams;
}

function updateListingParamsCookie(params: ListingParams) {
  Cookies.set(storageKey, JSON.stringify(params), { expires: DEFAULT_EXPIRE_DAYS });
}

//#endregion

//#region Helper Functions

/**
 * Ensure backwards compatibility with deprecated property types. If homeType includes attached, detached, or semidetached properties it will be a migrated to house.
 *
 * NOTE: deprecated properties are removed from the returned PropertyTypeFilter object. If house is not set or migrated, it will be set to true by default.
 *
 * @param propertyType The home type filter to update.
 * @returns The updated property type filter.
 */
function ensureBackwardCompatibilityForHomeType(propertyType: PropertyTypeFilter): PropertyTypeFilter {
  const deprecatedProperties: (keyof DeprecatedPropertyTypeFilter)[] = [HOUSE_DETACHED_PROPERTY_TYPE, HOUSE_SEMIDETACHED_PROPERTY_TYPE, HOUSE_ATTACHED_PROPERTY_TYPE];
  const hasDeprecatedProperty = deprecatedProperties.some(prop =>
    Object.prototype.hasOwnProperty.call(propertyType, prop)
  );

  if (hasDeprecatedProperty) {
    const isAnyDeprecatedPropertyTrue = deprecatedProperties.some(prop =>
      propertyType[prop as keyof PropertyTypeFilter]
    );

    deprecatedProperties.forEach(prop => {
      delete propertyType[prop as keyof PropertyTypeFilter];
    });
    propertyType.house = isAnyDeprecatedPropertyTrue;
  } else if (!Object.prototype.hasOwnProperty.call(propertyType, 'house')) {
    propertyType.house = true;
  }

  return propertyType;
}

//#endregion

export * from './types';