import React from 'react';

interface Props {
  height?: number;
  width?: number;
}

const MinusIcon = ({ height = 20, width = 20 }: Props) => {
  return (
    <svg width={`${width}px`} height={`${height}px`} viewBox={`0 0 ${width} ${height}`} version="1.1" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_24056_3542)">
        <path d="M15.8337 10.7332H4.16699V9.1665H15.8337V10.8332Z" fill="black"/>
      </g>
      <defs>
        <clipPath id="clip0_24056_3542">
          <rect width={width} height={height} fill="white"/>
        </clipPath>
      </defs>
    </svg>
  );
};

export default MinusIcon;