import { CountryCodeList } from 'types/countries';
import { ThemeConfig } from './themeConfig';
import { translate } from '../utils/translations';
import { SiteLanguage } from '../constants/locale';
import { EXP_CANADA_ADDRESS } from './exprealtyCA';
import keywordsCA from './keywordsCA';

export const ZOOCASA_ADDRESS = '52 Church St Suite\n464 Toronto, ON M5C 2B5';

const ZoocasaTheme: ThemeConfig = {
  name: 'Zoocasa',
  title: 'Zoocasa Real Estate Listings - Houses, Condos, Property & Home Values',
  metaDescription: "Find real estate listings for sale in Canada. Search MLS real estate listings for homes, condos and properties on Zoocasa.com, Canada's top real estate website.",
  largeDesktopHeaderImage: undefined,
  desktopHeaderImage: undefined,
  mobileHeaderImage: undefined,
  smallMobileImage: undefined,
  ogSiteName: 'Zoocasa.com',
  ogImage: 'https://www.zoocasa.com/next/assets/images/banner.png',
  schemaUrl: 'https://www.zoocasa.com',
  schemaLogo: 'https://www.zoocasa.com/assets/images/company/press/zoocasa-logo.jpg',
  schemaLocation: {
    addressCountry: 'Canada',
    addressLocality: 'Toronto',
    addressRegion: 'ON',
    postalCode: 'M5A4J5',
    streetAddress: '250 The Esplanade Suite 408',
  },
  schemaFoundingDate: '2015',
  schemaFounders: [
    {
      '@type': 'Person',
      name: 'Lauren Haw',
    },
  ],
  faviconPath: 'favicon-zoocasa.ico',
  colors: {
    primaryVeryLight: '#D8C19E',
    primaryLight: '#F8F5ED',
    primary: '#B38E57',
    primaryDark: '#000000',
    primaryVeryDark: '#886A3F',
    primaryBackground: '#181B1E',
    primaryFontColor: '#FFF',
    primaryForSale: '#4695C4',
    primarySold: '#DF6541',
    primarySoldFocus: '#191B1E',
    primaryFilters: '#4695c4',
    primaryLinkColor: '#4695c4',
    primaryFocusLinkColor: '#31739B',
    primaryInvertedBackground: '#191B1E',
    primaryBorderColor: '#B38E57',
    primaryFocusColor: '#47494B',
    primaryFocusBorderColor: '#47494B',
    primaryChart: '#F6F4F4',
    secondaryBackground: '#EFEFEF',
    secondaryBorderColor: '#181B1E',
    secondaryLinkColor: '#46484A',
    secondaryHoverColor: '#191B1E',
    secondaryFocusColor: '#47494B',
    secondaryFocusBorderColor: '#47494B',
    tertiaryBackground: '#FFF',
    tertiaryBackgroundLight: '#F3F4F4',
    tertiaryBorderColor: '#000000',
    tertiaryLinkColor: '#31587F',
    quaternaryLinkColor: '#31587F',
    quaternaryBorderColor: '#191B1E',
    quaternaryBackgroundLight: '#F3F4F4',
    transparentBackground: 'rgba(12, 15, 36, 0.5)',
    mapPinForSale: '#4695c4',
    mapPinForSaleFocus: '#191B1E',
    carouselArrowHover: '#F3F4F4',
    carouselArrowFocus: '#E8E8E8',
    notFound404PinHeadColor: '#D35313',
    notFOund404PinBodyColor: '#F26721',
  },
  fonts: {
    heading: 'Frank Ruhl Libre',
    text: 'Mulish',
    headingWeight: 'initial',
    headingBoldWeight: '600',
  },
  decorations: {
    brushstrokes: true,
    boxShadow: 'unset',
    boxShadowLight: '0 0 8px 0 rgba(0, 0, 0, .1);',
    areaPageLineWidth: '111px',
  },
  buttons: {
    borderRadius: '8px',
    borderRadiusSmall: '0px',
    borderRadiusMedium: '4px',
    borderRadiusLarge: '8px',
    borderRadiusModal: '0px',
  },
  icons: {
    borderRadius: '8px',
  },
  organization: {
    phoneNumber(isOnPreConPage) {
      return isOnPreConPage ? '1-647-952-6657' : '1-844-683-4663';
    },
    email(isOnPreConPage) {
      return isOnPreConPage ? 'newhomes@zoocasa.com' : 'info@zoocasa.com';
    },
    address(siteLocation) {
      return siteLocation === CountryCodeList.UNITED_STATES ? EXP_CANADA_ADDRESS : ZOOCASA_ADDRESS;
    },
    twitterUrl: 'https://x.com/zoocasa',
    facebookUrl: 'https://www.facebook.com/Zoocasa/',
    instagramUrl: 'https://www.instagram.com/zoocasa/',
    linkedinUrl: 'https://www.linkedin.com/company/zoocasa-com/',
  },
  menuLinks: [
    {
      label: 'Market Insights',
      link: 'https://www.zoocasa.com/blog/',
      isEmber: true,
    },
    {
      label: 'Find an Agent',
      link: '/company/our-agents',
      isEmber: true,
    },
    {
      label: 'New Construction',
      link: '/newhomes',
    },
    {
      label: 'Buy with Us',
      link: '/buy-with-zoocasa',
    },
    {
      label: 'Sell with Us',
      link: '/sell-with-zoocasa',
    },
    {
      label: 'Home Appraisal',
      link: '/home-appraisal',
      isEmber: true,
    },
    {
      label: 'New Listings',
      link: '/new-listings',
    },
    {
      label: 'Buying Guide',
      link: '/education-centre/buying',
      isEmber: true,
    },
    {
      label: 'Selling Guide',
      link: '/education-centre/selling',
      isEmber: true,
    },
    {
      label: 'Newsletter',
      link: 'https://zoocasa.ac-page.com/newsletter',
    },
    {
      label: 'Join as an Agent',
      link: 'https://careers.zoocasa.com#success-stories',
    },
    {
      label: 'Join our Team',
      link: 'https://careers.zoocasa.com/',
    },
    {
      label: 'Corporate Positions',
      link: 'https://careers.zoocasa.com#join-hq',
    },
    {
      label: 'Covid-19',
      link: '/company/covid-19',
      isEmber: true,
    },
    {
      label: 'Sitemap',
      link: '/sitemap',
    },
    {
      label: 'Chat',
    },
    {
      label: 'About Us',
      link: '/company/our-story',
      isEmber: true,
    },
  ],
  searchOptions: [
    {
      type: 'Buy',
      placeholder(siteLocation, isOnMobile) {
        const neighbourhood = translate('neighbourhood', siteLocation);
        return isOnMobile ? `City, ${neighbourhood}, or address` : `Enter a city, ${neighbourhood}, address, MLS® number or school`;
      },
    },
    {
      type: 'Rent',
      placeholder(siteLocation, isOnMobile) {
        const neighbourhood = translate('neighbourhood', siteLocation);
        return isOnMobile ? `City, ${neighbourhood}, or address` : `Enter a city, ${neighbourhood}, address, MLS® number or school`;
      },
    },
    {
      type: 'Sold',
      placeholder(siteLocation, isOnMobile) {
        const neighbourhood = translate('neighbourhood', siteLocation);
        return isOnMobile ? `City, ${neighbourhood}, or address` : `Enter a city, ${neighbourhood}, address, MLS® number or school`;
      },
    },
    {
      type: 'HomeAppraisal',
      placeholder() {
        return 'Enter your home address';
      },
    },
  ],
  contentBlockBuy: {
    title: 'Looking to Buy a Home?',
    copy: 'We deliver the best client experience and ensure home buyers have the tools they need to secure a property in today’s competitive market conditions. Connect with one of our team members today to learn more. ',
    image: '/next/assets/images/content-block/zoo-buy.jpg',
    link: '/buy-with-zoocasa',
  },
  contentBlockSell: {
    title: 'Thinking about Selling?',
    copy: 'We ensure every homeowner receives the most personalized and tailored service from our team.  Service that is as unique to them as their property. Connect with us now to get an in-home evaluation.',
    image: '/next/assets/images/content-block/zoo-sell.jpg',
    link: '/sell-with-zoocasa',
  },
  privacyPolicy: (language?: SiteLanguage) => {
    switch (language) {
    default:
      return 'https://www.zoocasa.com/privacy-policy';
    }
  },
  termsOfUse: (language?: SiteLanguage) => {
    switch (language) {
    default:
      return 'https://www.zoocasa.com/terms-of-use';
    }
  },
  keywords: keywordsCA,
};

export default ZoocasaTheme;
